
import { call, put, takeLatest } from 'redux-saga/effects'
import { CHANGE_PASSWORD } from '../App/constants'
import { changePasswordSuccess, changePasswordError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */  //let url = `${serverPath}/api/players/gettoken?foreignKey=${name}&firstname=${name}&password=${userPassword}&expirationDate=2020-01-01&access_token=${serverToken}&access=${access || 'premium'}`

export function * changePassword ({credentials}) {

  const changepswUrl = `${serverPath}/api/players/changePassword?id=${credentials.id}&password=${credentials.password}&access_token=${credentials.token}`
  const changepswagUrl = `${serverPath}/api/agencies/changePassword?id=${credentials.id}&password=${credentials.password}&access_token=${credentials.token}`
  const changepswsimUrl = `${serverPath}/api/playerTGDs/changePassword?id=${credentials.id}&password=${credentials.password}&access_token=${credentials.token}`

  const playerType = credentials.type.oldroute


  console.log(credentials.id, credentials.password)
  if (playerType === "/players"){
      try {
        yield call(request, changepswUrl, {method: 'POST'})
        yield put(changePasswordSuccess("0"))
      } catch (err) {
        yield put(changePasswordError(err))
      }
  } else if (playerType === "/simuplayers") {
    try {
      yield call(request, changepswsimUrl, {method: 'POST'})
      yield put(changePasswordSuccess("0"))
    } catch (err) {
      yield put(changePasswordError(err))
    }
  } else {
  try {
    yield call(request, changepswagUrl, {method: 'POST'})
    yield put(changePasswordSuccess("0"))
  } catch (err) {
    yield put(changePasswordError(err))
  }
 }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * changePasswordData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(CHANGE_PASSWORD, changePassword)
}