import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import Agencies from '../Agencies/index'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import car from '../../images/car.svg'

const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

const MainLayout = styled.div`
float: right;
display: flex;
flex-direction: column;
width: 80%;
background-color: white;
`
const ContentLayout = styled.div`
  display: inline;
  flex-direction: row;
  height: 100%;
`

const AgencyHomePage = () => {
  //   this.state = {
  //     name: '',
  //     password: '',
  //     errorCode: 0,
  //     topage: ''
  //   }

  // toCreation () {
  //   this.setState({ topage: 'create' })
  // }

  // toListing () {
  //   this.setState({ topage: 'stats' })
  // }

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        <MainTitle text='Les Agences' icon={car} />
        <ContentLayout>
          <Agencies />
        </ContentLayout>
      </MainLayout>
    </Bg>
  )
}

export default AgencyHomePage
