
import React from 'react'

const ShouldRefreshSimuPlayers = (props) => {
  if (props.refresh === true) {
    return (<div>{props.refreshSimuPlayers(props.agencytoken, props.agencyid, props.isAdmin)}</div>)
  }
  return (null)
}

export default ShouldRefreshSimuPlayers
