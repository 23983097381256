import React, { useState } from 'react'
import styled from 'styled-components'
import ArrowDown from '../../images/Arrowdown.svg'

const Button = styled.div`
  position: absolute;
  right: 1rem;  
  text-align:center;
  cursor:pointer;
  line-height:2.3rem;
`

const Icon = styled.img`
height: 0.8rem;
width:0.8em;
`
const Card = () => {
  const [showMenu, setShowMenu] = useState(false)

  const handleShowMenu = () => {
    setShowMenu(!showMenu)
    this.setState({ showMenu: !this.state.showMenu })
  }

  return (
    <Button onClick={() => { handleShowMenu() }}>
      {ArrowDown ? <Icon src={ArrowDown} /> : null}
    </Button>
  )
}

export default Card
