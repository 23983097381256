import { getStore } from '../../configureStore'

/**
 * @description
 * Adds access token to url
 * @param {*} string
 * @returns tokenized url
 */
export const tokenize = (url) => {
  const tokenizedUrl = url.match(/.+\?.+/)
    ? `${url}&access_token=${getStore().getState().get('global').get('agencytoken')}`
    : `${url}?access_token=${getStore().getState().get('global').get('agencytoken')}`
  return tokenizedUrl
}
