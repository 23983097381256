import { call, put, takeLatest } from 'redux-saga/effects'
import { SET_SCALE_EVAL } from '../App/constants'
import {
  setScaleEvalSuccess,
  setScaleEvalError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * setScaleEval ({ scale, id, token }) {
  const url = `${serverPath}/api/agencies/${id}?access_token=${token}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  const body = JSON.stringify({ evalScale: scale })
  const options = {
    method: 'PATCH',
    headers,
    body
  }

  try {
    yield call(request, url, options)
    yield put(setScaleEvalSuccess(scale))
  } catch (err) {
    yield put(setScaleEvalError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * setScaleEvalSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SET_SCALE_EVAL, setScaleEval)
}
