import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../SideBar'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import MainTitle from '../../components/MainTitle'
import ArchiveUi from '../../images/box.svg'
import SimuPlayersArchive from '../SimuPlayerArchive/index'

const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
`
const ContentLayout = styled.div`
  display: inline;
  flex-direction: row;
  height: 100%;
`

const SimuPlayersArchivePage = () => {
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  //   this.state = {
  //     name: '',
  //     password: '',
  //     errorCode: 0,
  //     topage: '',
  //     close: '',
  //     vanish: ''
  //   }

  // toCreation () {
  //   this.setState({ topage: 'create' })
  // }

  // toListing () {
  //   this.setState({ topage: 'stats' })
  // }

  // generatesDate () {
  //   const date = new Date()
  //   let day = date.getDate()
  //   let mounth = date.getMonth() + 1
  //   const year = date.getFullYear()
  //   if (day < 10) {
  //     day = '0' + day
  //   }
  //   if (mounth < 10) {
  //     mounth = '0' + mounth
  //   }
  //   const dateRefactor = day + '/' + mounth + '/' + year
  //   return dateRefactor
  // }

  return (
    <Bg>
      <SideBar isAdmin={isAdmin} />
      <MainLayout>
        <MainTitle text='Élèves archivées' heightImg={1.3} icon={ArchiveUi} />
        <ContentLayout>
          <SimuPlayersArchive />
        </ContentLayout>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null)
const arg = compose(withConnect)(SimuPlayersArchivePage)

export default arg
