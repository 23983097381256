
import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import '../../fonts/fonts.css'
import { agencyLoginData, agencyCheckTokenData } from './saga'
import injectSaga from '../../utils/injectSaga'
import { agencyLogin, agencyCheckToken } from '../App/actions'
import InputPassword from '../../components/InputPassword'
import ElearningLogo from '../../images/elearningLogo.svg'
import MLLogo from '../../imagesMissionLocale/MLLOGO.png'
import LegalMentionImg from '../../images/Legal_mention.svg'
import config from '../../config/configurator'
import { useHistory } from 'react-router'
import SimuLogo from '../../images/simuLogo.png'
import VinciLogo from '../../imagesVinci/LOGO_ASF.svg'
import { Bg, VerticalContainer, LogoImg, Title, Id, Mdp, Input, Button, TextButton, FeedBack, LegalMention, BottomLogoContainer, BottomLogo, LegalMentionText } from './styledComponent'

export function LoginPage () {
  const dispatch = useDispatch()
  const history = useHistory()

  const loginStatus = useSelector((state) => state.toJS().global.loginStatus)
  const error = useSelector((state) => state.toJS().global.error)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)

  const [agencyName, setAgencyName] = useState('')
  const [agencyPassword, setAgencyPassword] = useState('')

  const login = () => {
    dispatch(agencyLogin({ agencyname: agencyName, agencypassword: agencyPassword }))
    history.push(config.features.simu ? '/simuplayers' : 'players')
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  }

  useEffect(() => {
    if (agencytoken != null) {
      dispatch(agencyCheckToken({ token: agencytoken }))
    }
    // eslint-disable-next-line
  }, [agencytoken])

  switch (loginStatus) {
    case 'connected':
      return null
    case 'checking token':
      return (
        <>
          <div>VERIFICATION</div>
        </>
      )
    default:
      return (
        <Bg>
          <VerticalContainer>
            {/* <LogoImg src={'../../' + config.imgs.root + '/' + config.layouts.sidebar.imgLogo} /> */}
            <LogoImg src={config.title === 'ML ADMIN' ? MLLogo : ElearningLogo && config.title === "VINCI" ? VinciLogo : VinciLogo} />
            <Title>Console de gestion</Title>
            <Id>Identifiant</Id>
            <Input onKeyDown={onKeyPress} value={agencyName} onChange={(e) => setAgencyName(e.target.value)} />
            <Mdp>Mot de passe</Mdp>
            <InputPassword borderColor={error ? 'red' : '#dadada'} onChange={(value) => setAgencyPassword(value)} />
            <Button data-type='premium' onClick={login}>
              <TextButton>
                Connexion
              </TextButton>
            </Button>
            {error ? <FeedBack>Identifiant ou mot de passe incorrect</FeedBack> : null}
            {config.title === 'ML ADMIN'
              ? <BottomLogoContainer>
                <BottomLogo src={SimuLogo} />
                <LegalMentionText>© 2021 The Good Drive. All Rights Reserved.</LegalMentionText>
                </BottomLogoContainer>
              : <LegalMention src={LegalMentionImg} />}
          </VerticalContainer>
        </Bg>
      )
  }
}

const withConnect = connect(null, null)
const withSagaLogin = injectSaga({ key: 'LoginPage', saga: agencyLoginData })
const withSagaTokenCheck = injectSaga({ key: 'TokenCheck', saga: agencyCheckTokenData })
const arg = compose(withSagaTokenCheck, withSagaLogin, withConnect)(LoginPage)

export default arg
