const CERsimu = require('./configs/CERsimu')
const PPsimu = require('./configs/PPsimu')
const T5Journey = require('./configs/T5Journey')
const root = require('./configs/root')
const EvalPP = require('./configs/EvalPP')
const MissionLocale = require('./configs/MissionLocale')
const MarqueBlanche = require('./configs/MarqueBlanche')
const Vincisimu = require('./configs/Vincisimu')
module.exports = {
  CERsimu,
  PPsimu,
  T5Journey,
  EvalPP,
  root,
  MissionLocale,
  MarqueBlanche,
  Vincisimu
}
