import styled from 'styled-components'

export const Bg = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  background-color: white;
  width: 16.5%;
  height: 18.33%;
  font-family: Poppins;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`

export const Title = styled.div`
  width: 85%;
  margin-left: 8%;
  margin-top: 6%;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
`

export const Name = styled.div`
  margin-top: 8px;
  width: 60%;
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  margin-left: 8%;
  `
export const Icon = styled.img`
  height: 80%;
  margin-top: 0;
  margin-right: 4%;
  margin-bottom: 2%;
`

export const ButtonCancel = styled.button`
  height: 38px;
  width: 33.75%;
  background-color: white;
  border: solid 0.1rem grey;
  border-radius: 0.5rem;
  color: #868383;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }
`

export const ButtonAction = styled.button`
  height: 38px;
  width: 33.75%;
  background-color: #61D7F3;
  color: black;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight 500;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.14);

  &:hover {
    cursor: pointer;
  }
`

export const ContentBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
  padding-bottom: 32px;
`
export const ContentText = styled.div`
  height: 50%;
`
