
import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_TOKEN_SIMU } from '../App/constants'
import { getTokenSimuSuccess, getTokenSimuError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */
export function * getTokenSimu ({ credentials }) {
  const getTokenUrl = `${serverPath}/api/playerTGDs/gettoken?idTGD=${credentials.email}&firstname=${credentials.firstname}&lastname=${credentials.lastname}&email=${credentials.email}&password=${credentials.password}&entity=${credentials.entity}&access_token=${credentials.token}`

  try {
    const { token } = yield call(request, getTokenUrl, { method: 'GET' })
    yield put(getTokenSimuSuccess(token))
  } catch (err) {
    yield put(getTokenSimuError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getTokenSimuSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_TOKEN_SIMU, getTokenSimu)
}
