/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_SIMU_PLAYER, REFRESH_SIMU_PLAYERS } from '../App/constants'
import { deleteSimuPlayerSuccess, deleteSimuPlayerError, refreshSimuPlayersError, refreshSimuPlayersSuccess } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * deleteSimuPlayer ({ token, id, agencyid }) {
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/playerTGDs/${id}?access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/playerTGDs?filter[where][agencyId]=${agencyid}&access_token=${token}`
  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlPlayerDelete, { method: 'DELETE' })
    yield call(request, urlPlayers, { method: 'GET' })

    yield put(deleteSimuPlayerSuccess(/* players */))
  } catch (err) {
    yield put(deleteSimuPlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteSimuPlayerSaga () {
  yield takeLatest(DELETE_SIMU_PLAYER, deleteSimuPlayer)
}

export function * refreshSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`

  let players = []
  const UrlsProg = []

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    players.map(x => {
      return UrlsProg.push(`${serverPath}/api/playerTGDs/${x.id}/saveREMC?access_token=${token}`)
    })
    yield * UrlsProg.map(function * (UrlProg, i = 0) {
      players[i].prog = yield call(request, UrlProg, { method: 'GET' })
    })
    yield put(refreshSimuPlayersSuccess(players))
  } catch (err) {
    yield put(refreshSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshSimuPlayersSaga () {
  yield takeLatest(REFRESH_SIMU_PLAYERS, refreshSimuPlayers)
}
