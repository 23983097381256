
import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_TOKEN_AGENCY } from '../App/constants'
import { getTokenAgencySuccess, getTokenAgencyError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */
export function * getToken ({ name, password, access, token, logo, email }) {
  const getTokenUrl = `${serverPath}/api/agencies/gettoken?agencyname=${name}&agencykey=${name}&expirationDate=2030-01-01&access=premium&password=${password}&logo=${logo}&notificationEmail=${email}&access_token=${token}`
  try {
    // Call our request helper (see 'utils/request')
    const { token } = yield call(request, getTokenUrl, { method: 'GET' })
    yield put(getTokenAgencySuccess(token))
  } catch (err) {
    yield put(getTokenAgencyError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getTokenSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_TOKEN_AGENCY, getToken)
}
