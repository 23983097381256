import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
width:2rem;
margin-left:9rem;
`

const Icon = styled.img`
height: 1.25rem;
width:1.25rem;
`

const ButtonArrow = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default ButtonArrow
