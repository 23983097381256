import React, { useState } from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import Input from '../../components/Input'
import Agence from '../../images/Pagence.svg'
import ButtonSaveModif from '../../components/ButtonSaveModif'
const Bg = styled.div`
    min-height: 100vh;
    height: 100vh;
    width: 100%;
`

const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 85%;
  background-color: white;
  justify-content:center;
  align-items:center;
`
const Name = styled.div`
font-family:Poppins;
font-weight:300;
font-size:0.75rem;
font-style:normal;
color:#4F4F4F;
`

const FormContainer = styled.div`
  margin:auto;
  position:relative;
  margin-top:3rem;
  width:30%;
`

const Agencyprofile = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        <MainTitle text='Profile Agence' icon={Agence} />
        <FormContainer>
          <Name>Nom de l'agence</Name>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
          <Name>Email</Name>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          <ButtonSaveModif text='Enregistrer les modifications' />
        </FormContainer>
      </MainLayout>
    </Bg>
  )
}

export default Agencyprofile
