/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import './AgGrid.css'
import ModalConfirm from '../../components/ModalConfirm/index.js'
import { deleteSimuPlayerSaga, refreshSimuPlayersSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deleteSimuPlayer, refreshSimuPlayers } from '../App/actions'
import { Bg, ActionContainer, ActionEdit, EditIcon, DeleteIcon, ArchiveIcon, ActionDelete,ActionArchive, EvalBar, Progression, ProgressionBar, ProgressionBarIn, ProgressionText, TopBar, SimulBar, FirstContainer, ContainerFirstStudent, AvatarContainer, TextFirstCSV, ButtonImport, ImportImg, ImportText, ButtonCreate } from './styledComponents'
import Avatar4 from '../../images/Avatar4.svg'
import AddStudent from '../../images/Add.svg'
import config from '../../config/configurator'
import UiEdit from '../../images/edit.svg'
import UiDelRed from '../../images/closeRed.svg'
import UiBoy from '../../images/boy.svg'
import UiArchive from '../../images/Archive.svg'
import { AgGridReact } from 'ag-grid-react'
import UiMail from '../../images/mail.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { Link } from 'react-router-dom'
import { ConfigService } from 'aws-sdk'

// FIXME: See -> React Data Grid: Group Cell Renderer.

const SimuPlayers = () => {
  const dispatch = useDispatch()

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyid = useSelector((state) => state.toJS().global.agencyid)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  // const [showPanel, setShowPanel] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalName, setModalName] = useState('')
  const [playerId, setPlayerId] = useState('')
  // const [textPanel, setTextPanel] = useState('')

  useEffect(() => {
    dispatch(refreshSimuPlayers(agencytoken, agencyid, isAdmin))
    // eslint-disable-next-line
  }, [])

  function findAgencyName (agencyId) {
    if (agencies == null) {
      return ''
    }
    if (agencyId == null) {
      return 'Non affilié'
    }
    const agency = agencies.find(
      a => a.id.toString() === agencyId.toString()
    )
    return agency != null ? agency.agencyname : 'Aucune agence'
  }

  function convertInTime (time, access) {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt(time / 60 % 60)
      const s = parseInt(time % 60)

      return h + 'h ' + m + 'm' + s + 's'
    }
    return 'Non démarré'
  }

  function confirmDelete (id, action, firstname) {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  function deletePlayer (id) {
    dispatch(deleteSimuPlayer(agencytoken, id, agencyid))
  }

  const buildRows = () => {
    const rowData = []
    if (!simuPlayers) { return null }
    simuPlayers.forEach((player) => {
      const pTime = player.prog ? convertInTime(player.prog.PlayTime, player.access) : 0
      const pCount = player.prog && player.prog.Completion ? player.prog.Completion.Count : 0
      const pTotal = player.prog && player.prog.Completion ? player.prog.Completion.CountTotal : 0

      rowData.push({
        eleve: player.firstname + ' ' + player.lastname,
        email: player.email,
        agence: findAgencyName(player.agencyId),
        agencyId: player.agencyId,
        permis: player.id_permis || 'B',
        conduite: pTime,
        id: player.id,
        eval: player.eval ? player.eval.totalScore : '---',
        date: player.created_at !== null ? moment(player.created_at).format('DD/MM/YYYY') : player.eval.endDate.format('DD/MM/YYYY'),
        volume: player.eval ? player.eval.EvaluationBareme : '---',
        pCount: pCount,
        pTotal: pTotal
      })
    })
    return rowData
  }
  const rowHeight = 55
   const basicGrid = [
    { width: config.features.eval === true ? '180px' : '244px', suppressMovable: true, headerName: 'Elèves', field: 'eleve', cellClass:' rowGreyBackgroundwithImg', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{ paddingLeft:'1.8rem', textOverflow: 'ellipsis',lineHeight:'1.1rem' }} to={{ pathname: `playersimu/edit/${params.data.id}`, query: { id: params.data.id } }}>{params.data.eleve}</Link> } },
    { width: config.features.eval === true ? '180px' : '244px', suppressMovable: true, headerName: 'Email', field: 'email', cellClass:' rowGreyBackground',floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true } },
    { width: config.features.eval === true ? '100px' : '144px', suppressMovable: true, headerName: 'Permis', field: 'permis', cellClass:' rowGreyBackground',suppressMenu: true}
  ]

  const AgencyGrid = { width: config.features.eval === true ? '190px' : '259px', suppressMovable: true, headerName: 'Agence', field: 'agence', cellClass:' rowGreyBackgroundwithAgencyImg',floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{ paddingLeft:'1.8rem', textOverflow: 'ellipsis',lineHeight:'1.3rem', cursor: params.data.agence === 'Aucune agence' ? 'unset' : 'pointer ' }} to={{ pathname: params.data.agence === 'Aucune agence' ? '' : `agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>{params.data.agence}</Link> } }
  
  const evalGrid = [
    { width: 98, suppressMovable: true, headerName: 'Eval\'', field: 'eval', cellClass:'rowGreenBackground', suppressMenu: true },
    { width: 116, suppressMovable: true, headerName: 'Volume', field: 'volume', cellClass:'rowGreenBackground', suppressMenu: true},
    { width: 116,suppressMovable: true, headerName: 'Date', field: 'date', cellClass:'rowGreenBackground', suppressMenu: true }
  ]
  const simuGrid = [
    { width: config.features.eval === true ? '100px' : '138px', suppressMovable: true, headerName: 'Conduite', field: 'conduite',cellClass:'rowBlueBackground', suppressMenu: true, cellRendererFramework: (params) => { return <Link style={{fontWeight:'500',lineHeight:'3.2rem'}} to={{ pathname: `/simuplayers/stats/${params.data.id}` }}><div>{params.data.conduite}</div></Link> } },
    {
      suppressMovable: true,
      suppressMenu: true,
      field: 'Progression',
      cellClass:'rowBlueBackground',
      width: config.features.eval === true ? '130px' : '196px',
      cellRendererFramework: (params) => {
        return (
          <Link to={{ pathname: `/simuplayers/stats/${params.data.id}`, query: { id: params.data.id } }}>
            <Progression>
              <ProgressionBar style={{width: config.features.eval === true ? '60%' : '80%' , marginLeft: config.features.eval === true ? '0.2rem' : '-0.7rem'}}>
                <ProgressionBarIn style={{ width: `${params.data.pCount ? params.data.pCount / params.data.pTotal * 100 : 0}%` }} />
              </ProgressionBar>
              <ProgressionText style={{marginLeft: config.features.eval === true ? '-0.6rem' : '0.5rem'}}>{params.data.pCount}/{params.data.pTotal}</ProgressionText>
            </Progression>
          </Link>
        )
      }
    }
  ]

  const actionsGrid = [
    {
      suppressMovable: true,
      width: config.features.eval === true ? '128px' : '100px',
      suppressMenu: true,
      field: 'Actions',
      cellClass: 'rowGreyBackground',
      cellRendererFramework: (params) => {
        return (
          <ActionContainer>
            <Link to={`playersimu/edit/${params.data.id}`}>
              <ActionEdit style={{marginLeft: config.features.eval === true ? '7%' : ''}}>
                <EditIcon src={config.features.eval === true ? UiMail : UiEdit} />
              </ActionEdit>
            </Link>
            {/* <ActionArchive onMouseEnter={() => { showPanel(true, 'Supprimer') }} onMouseLeave={() => showPanel(false, '')} onClick={() => confirmDelete(id, 'Vous allez supprimer', name)}> */}
            <ActionDelete onClick={() => confirmDelete(params.data.id, 'Supprimer', params.data.eleve)} style={{marginLeft: config.features.eval === true ? '-7%' : ''}}>
              <DeleteIcon
                src={ config.features.eval === true ? UiEdit : UiDelRed}
              />
            </ActionDelete>
            {config.features.eval === true ? <ActionArchive> <ArchiveIcon src={UiArchive}/></ActionArchive> : '' }
          </ActionContainer>
        )
      }
    }
  ]
  const adminGrid = () => {
    basicGrid.splice(2,0,AgencyGrid)
    return basicGrid
  }
  const gridOptions = {
    defaultColDef: { filter: true, sortable: true },
    columnDefs: (!isAdmin && config.features.simu) 
    ? basicGrid.concat(simuGrid).concat(actionsGrid)
    : (!isAdmin && !config.features.simu)
      ? basicGrid.concat(simuGrid).concat(actionsGrid)
      : (config.features.simu && !config.features.eval)
        ? adminGrid().concat(simuGrid).concat(actionsGrid)
        : (!config.features.simu && config.features.eval)
          ? adminGrid().concat(evalGrid).concat(actionsGrid)
          : (config.features.simu && config.features.eval)
              ? adminGrid().concat(evalGrid).concat(simuGrid).concat(actionsGrid)
              : adminGrid().concat(actionsGrid)
  }

  if (simuPlayers == null) {
    return <div>LOADING</div>
  }

  const hasPlayers = simuPlayers.length > 0

  return (
    <Bg>
      {hasPlayers
        ? <TopBar style={{ marginLeft: isAdmin ? '35.5%' : '27.5%' }}>
          {isAdmin && config.features.eval ? <EvalBar style={{ width: isAdmin ? '36%' : '31%', marginLeft: config.features.eval === true ? '0%' : '27.5%' }}>Evaluation</EvalBar> : ''}
          <SimulBar style={{ width: isAdmin && config.features.eval ? '41%' : isAdmin && !config.features.eval ? '39%' : '47%', marginLeft: isAdmin && config.features.eval ? '3%' : isAdmin && !config.features.eval ? '28%' : '44%', paddingBottom: '0.2rem' }}>Simulation</SimulBar>
        </TopBar>
        : null}
      <ModalConfirm
        show={showModal}
        text={modalText}
        name={modalName}
        confirm={() => { deletePlayer(playerId); setShowModal(false) }}
        cancel={() => setShowModal(false)}
      />
      {/* {showPanel ? <HoverPanel style={{ top: '2rem', left: '2rem' }}>{textPanel}</HoverPanel> : ''} */}
      {hasPlayers
        ? <div className={ isAdmin && config.features.eval ? 'ag-theme-alpine-Admin-Eval' : isAdmin && config.features.eval === false ? 'ag-theme-alpine' : 'ag-theme-alpine'} style={{ height: 600, width: 1375 }}>
        <AgGridReact
          rowData={buildRows()}
          gridOptions={gridOptions}
          rowHeight={rowHeight}
        />
        </div>
        : <FirstContainer>
          <ContainerFirstStudent>
            <AvatarContainer>
              <img alt='avatar 4' src={Avatar4} />
            </AvatarContainer>
            <TextFirstCSV>Vous pouvez <b>créer un élève</b> dans votre console de gestion</TextFirstCSV>
            <ButtonImport>
              <ButtonCreate to='/playersimu/create' style={{ width: '173px' }}>
                <ImportImg src={AddStudent} />
                <ImportText>Créer un élève</ImportText>
              </ButtonCreate>
            </ButtonImport>
          </ContainerFirstStudent>
        </FirstContainer>}
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'deleteSimuPlayer', saga: deleteSimuPlayerSaga })
const withRefresh = injectSaga({ key: 'refreshSimuPlayers', saga: refreshSimuPlayersSaga })
const arg = compose(withSaga, withRefresh, withConnect)(SimuPlayers)

export default arg
