import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import pushdown from '../../images/push-down.svg'
const Bg = styled.div`
    min-height: 100vh;
    height: 100vh;
    width: 100%;
`
const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 85%;
  background-color: white;
  justify-content:center;
  align-items:center;
`
const FormContainer = styled.div`
  margin:auto;
  position:relative;
  margin-top:3rem;
  width:30%;
`
const Downloads = styled.div`
height:184px;
background:linear-gradient(180deg,#F5F5F7 0%, #EDF0F7 100%);
border-radius:10px;
margin-top:1.5rem;
display:flex;
flex-direction:column;
`
const Title = styled.div`
font-style:normal;
font-size:1.5rem;
font-family:Poppins;
font-weight:600;
color:black;
margin-left:2rem;
margin-top:2rem;
`
const Text = styled.div`
font-style:normal;
font-size:0.9rem;
font-family:Poppins;
font-weight:400;
color:black;
margin-left:2rem;
`
const Button = styled.div`
position:absolute;
width:9.5rem;
height:3rem;
text-align:center;
line-height:3rem;
font-style:normal;
font-family:Poppins;
font-weight:500;
font-size:1rem;
color:black;
background-color:#61D7F3;
border-radius:10px;
box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
margin-left:2rem;
margin-top:-5rem;
cursor:pointer;
`

const Button1 = styled.div`
position:absolute;
width:9.5rem;
height:3rem;
text-align:center;
line-height:3rem;
font-style:normal;
font-family:Poppins;
font-weight:500;
font-size:1rem;
color:black;
background-color:#61D7F3;
border-radius:10px;
box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
margin-left:2rem;
margin-top:7.2rem;
cursor:pointer;
`

const Download = () => {
  return (
    <Bg>
      <SideBar />
      <MainLayout>
        <MainTitle text='Téléchargements' icon={pushdown} />
        <FormContainer>
          <Downloads>
            <Title>Téléchargez le simulateur</Title>
            <Text>Windows uniquement</Text>
          </Downloads>
          <Button>Télécharger</Button>
          <Downloads>
            <Title>Téléchargez l'évaluation</Title>
            <Text>Windows uniquement</Text>
            <Button1>Télécharger</Button1>
          </Downloads>

        </FormContainer>
      </MainLayout>
    </Bg>
  )
}

export default Download
