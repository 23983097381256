import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container2 = styled.button`
  width: 16.75rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1.1rem;
  margin-top:2rem;
  font-weight:500;
  color: black;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 5rem;
  margin-left:16rem;
  padding-left:1.5rem;
  left:61rem;
  right:41rem;
  line-height:3rem;
  display: flex;
  flex-direction:row;
  align-items:flex-start;
  background: #61D7F3;
  box-shadow:0px 2px 7px rgba(0,0,0,0.14);
`
const Icon = styled.img`
  height: 1rem;
  width:1rem;
  margin-right: 1rem;
`

export const Button2 = (props) => {
  const onClick = props.onClick || (() => {})

  if (props.to == null) {
    return (
      <Container2 {...props} onClick={() => onClick()}>
        {props.icon ? <Icon src={props.icon} /> : null}
        {props.text}
      </Container2>
    )
  }

  return (
    <Link to={props.to} style={{ textDecoration: 'none' }}>
      <Container2 {...props} onClick={() => onClick()}>
        {props.icon ? <Icon src={props.icon} /> : null}
        {props.text}
      </Container2>
    </Link>
  )
}

export default Button2
