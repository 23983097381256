import { AGENCY_LOGIN, AGENCY_CHECK_TOKEN } from '../App/constants'
import { agencyLoginsuccess, agencyLoginerror, agencyCheckTokensuccess, agencyCheckTokenerror } from '../App/actions'
import { call, put, takeLatest } from 'redux-saga/effects'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * agencyLogin ({ credentials }) {
  const loginUrl = `${serverPath}/api/agencies/gettokenfromagencyname?agencyname=${credentials.agencyname}&password=${credentials.agencypassword}`

  try {
    const { login: { token, id, isAdmin, evalScale } } = yield call(request, loginUrl)
    yield put(agencyLoginsuccess(token, id, isAdmin, evalScale, credentials.agencyname))
  } catch (err) {
    yield put(agencyLoginerror(err.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * agencyLoginData () {
  yield takeLatest(AGENCY_LOGIN, agencyLogin)
}

export function * agencyCheckToken ({ token }) {
  console.log('TOKEN', token.token)
  const loginUrl = `${serverPath}/api/agencies/getagencyfromtoken?token=${token.token}`

  try {
    const { login: { token, id, isAdmin, evalScale, agencyname } } = yield call(request, loginUrl)
    yield put(agencyCheckTokensuccess(token, id, isAdmin, evalScale, agencyname))
  } catch (err) {
    yield put(agencyCheckTokenerror(err.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * agencyCheckTokenData () {
  yield takeLatest(AGENCY_CHECK_TOKEN, agencyCheckToken)
}
