/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_SIMU_PLAYER, REFRESH_SIMU_PLAYERS } from '../App/constants'
import { deleteSimuPlayerSuccess, deleteSimuPlayerError, refreshSimuPlayersError, refreshSimuPlayersSuccess } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * archiveSimuPlayer ({ token, idTGD, agencyid }) {
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerArchive = `${serverPath}/api/playerTGDs/togglearchive?id_TGD=${idTGD}&access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/playerTGDs?filter[where][agencyId]=${agencyid}&access_token=${token}`
  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlPlayerArchive, { method: 'POST' })
    yield call(request, urlPlayers, { method: 'GET' })

    // yield put(deleteSimuPlayerSuccess(/* players */)) no redux action for now
  } catch (err) {
    // yield put(deleteSimuPlayerError(err))
  }
}

export function * deleteSimuPlayer ({ token, id, agencyid }) {
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/playerTGDs/${id}?access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/playerTGDs?filter[where][agencyId]=${agencyid}&access_token=${token}`
  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlPlayerDelete, { method: 'DELETE' })
    yield call(request, urlPlayers, { method: 'GET' })

    yield put(deleteSimuPlayerSuccess(/* players */))
  } catch (err) {
    yield put(deleteSimuPlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteSimuPlayerSaga () {
  yield takeLatest(DELETE_SIMU_PLAYER, deleteSimuPlayer)
}

export function * refreshSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  let remc
  let players = []

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    remc = yield call(request, urlREMC, { method: 'GET' })

    players.forEach(pl => {
      const tmpProg = remc.filter(pr => { return pr.id === pl.id })
      pl.prog = tmpProg[0] ? tmpProg[0].save : {}
    })

    yield put(refreshSimuPlayersSuccess(players))
  } catch (err) {
    yield put(refreshSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshSimuPlayersSaga () {
  yield takeLatest(REFRESH_SIMU_PLAYERS, refreshSimuPlayers)
}
