import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SimuPlayers from '../SimuPlayers/index'
import SideBar from '../SideBar/index'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import MainTitle from '../../components/MainTitle'
import user from '../../images/boy.svg'
import PopUpdate from '../PopUpdate/index'

const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
`
const ContentLayout = styled.div`
  display: inline;
  flex-direction: row;
  height: 100%;
`

const SimuPlayersPage = () => {
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  // TODO: What for ?

  // const [name, setName] = useState()
  // const [password, setPassword] = useState()
  // const [errorCode, setErrorCode] = useState(0)
  // const [topage, setTopage] = useState()
  // const [close, setClose] = useState()
  // const [vanish, setVanish] = useState()

  // const toCreation = () => {
  //   setTopage('create')
  // }

  // const toListing = () => {
  //   setTopage('stats')
  // }

  // const generatesDate = () => {
  //   const date = new Date()
  //   let day = date.getDate()
  //   let mounth = date.getMonth() + 1
  //   const year = date.getFullYear()
  //   if (day < 10) {
  //     day = '0' + day
  //   }
  //   if (mounth < 10) {
  //     mounth = '0' + mounth
  //   }
  //   const dateRefactor = day + '/' + mounth + '/' + year
  //   return dateRefactor
  // }

  return (
    <Bg>
      <SideBar isAdmin={isAdmin} />
      <MainLayout>
        <MainTitle text='Les élèves' icon={user} />
        <ContentLayout>
          <PopUpdate />
          <SimuPlayers />
        </ContentLayout>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null)
const arg = compose(withConnect)(SimuPlayersPage)

export default arg
