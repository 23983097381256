/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_TOKEN, GET_AGENCYTOKEN } from '../App/constants'
import { getTokenSuccess, getTokenError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */
export function * getToken ({ name, password, access, userPassword }) {
  const getTokenUrl = `${serverPath}/getToken/${name}/${password}/${access}/${userPassword}/`

  try {
    // Call our request helper (see 'utils/request')
    const { token } = yield call(request, getTokenUrl, { method: 'POST' })
    yield put(getTokenSuccess(token))
  } catch (err) {
    yield put(getTokenError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getTokenSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_TOKEN, getToken)
}

// TO CHECK
export function * getAgencyToken ({ name, password }) {
  const getTokenUrl = `${serverPath}/getAgencyToken/${name}/${password}/`

  try {
    const { token } = yield call(request, getTokenUrl, { method: 'POST' })
    yield put(getTokenSuccess(token))
  } catch (err) {
    yield put(getTokenError(err))
  }
}

export function * getAgencyTokenSaga () {
  yield takeLatest(GET_AGENCYTOKEN, getAgencyToken)
}
