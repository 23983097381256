import { call, put, takeLatest } from 'redux-saga/effects'
import { AGENCY_EDIT, DELETE_AGENCY } from '../App/constants'
import {
  agencyEditSuccess,
  agencyEditError,
  deleteAgencySuccess,
  deleteAgencyError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`
/**
 * Github repos request/response handler
 */

export function * deleteAgency ({ token, id }) {
  const urlAgencyDelete = `${serverPath}/api/agencies/${id}?access_token=${token}`
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`

  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlAgencyDelete, { method: 'DELETE' })
    const agencies = yield call(request, urlAgencies, { method: 'GET' })
    yield put(deleteAgencySuccess(agencies))
  } catch (err) {
    yield put(deleteAgencyError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteAgencySaga () {
  yield takeLatest(DELETE_AGENCY, deleteAgency)
}

export function * agencyEdit ({ credentials }) {
  const editAgencyUrl = `${serverPath}/api/agencies/editAgency?id=${credentials.id}&agencyname=${credentials.agencyname}&email=${credentials.email}${credentials.logo ? `&logo=${credentials.logo}` : ''}${credentials.password ? `&password=${credentials.password}` : ''}&notificationEmail=${credentials.email}&access_token=${credentials.token}`
  const getAgencyUrl = `${serverPath}/api/agencies/${credentials.id}?access_token=${credentials.token}`

  try {
    yield call(request, editAgencyUrl, { method: 'PATCH' })
    const agency = yield call(request, getAgencyUrl, { method: 'GET' })
    yield put(agencyEditSuccess(agency))
  } catch (err) {
    console.log(err)
    yield put(agencyEditError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * agencyEditData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(AGENCY_EDIT, agencyEdit)
}
