import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
width:1rem;
display:flex;
display:inline;
align-self:center;
position:absolute;
top:18.75%
bottom:16.29%;
padding-left:1rem;
`

const Icon = styled.img`
height: 1rem;
width:1.25rem;
`

const ButtonArrowRight = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default ButtonArrowRight
