import styled from 'styled-components'
import Delete from '../../images/delete.svg'
import UiBoy from '../../images/boy.svg'
import UiCar from '../../images/car.svg'
import { Link } from 'react-router-dom'
import config from '../../config/configurator'

export const ActionContainer = styled.div`
display: flex;
align-items: center;
`

export const ActionEdit = styled.div`
display: flex;
flex-direction: center;
justify-content: center;
align-items: center;
height: 1.6vw;
min-width: 1.6vw;
border-radius: 0.225rem;
cursor: pointer;

&:hover {
   background-color: #AAE5F3;
 }
 border: ${config.colors.ActionMail};
background-color: white;
`

export const ActionArchive = styled.div`
  margin-left: 8px;
  border: ${config.colors.ActionMail};
  background-color: white;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;
  &:hover {
     background-color: #AAE5F3;
   }
`

export const EditIcon = styled.img`
height: 0.8vw;
object-fit: content;
`

export const DeleteIcon = styled.img`
  height: 0.7vw;
`

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const Button = styled.button`
  width: 16.3rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style:normal;
  text-align: center;
  color:#868383;
  margin-top: 0.5rem;
  border-radius: 10px;
  background-color:#EFF0F6;
  border: unset;
`
export const DeleteButton = styled.button`
  width: 8.8rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style:normal;
  text-align: center;
  color:#868383;
  margin-top: 0.5rem;
  padding-left: 5%;
  cursor: pointer;
  border-radius: 10px;
  background-color:#EFF0F6;
  background-image: url(${Delete});
  background-repeat: no-repeat;
  border: unset;
  background-position: bottom 50% left 12%;
`

export const MainLayout = styled.div`
float: right;
display: flex;
flex-direction: column;
width: 84.88%;
background-color: white;
`

export const ErrorText = styled.div`
  font-family: Catamaran;
  font-size: 1rem;
  font-color: red;
  color: red;
  font-weight: 600;
  text-align: center;
  margin: auto;
  margin-bot: 1rem;
`
export const FormContainer = styled.div`
  margin:auto;
  position:relative;
  margin-top:3rem;
  width:35%;
`

export const BoxContainer = styled.div`
  display:flex;
  flex-direction:column;
  &:nth-of-type(6){
    margin-top: 0.5rem;
  }
`

export const Input = styled.input`
  width:100%;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  height: 2.6rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3; outline: none}
`

export const Name = styled.div`
font-family:Poppins;
font-weight:300;
font-size:0.8rem;
font-style:normal;
margin-left: 1rem;
color:#4F4F4F;
`

export const SelectorAgencyContainer = styled.div`
  text-align:center;
  margin-bottom: 1rem;
  margin-top: 0.7rem;
`

export const SelectorStudentContainer = styled.div`
  text-align:center;
  margin-bottom: 1rem;
  position: relative;
`

export const FeedBack = styled.div`
  color: red;
  margin-left: 1rem;
  margin-top:-0.7rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
`

export const SimuPlayerSelector = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 35%;
  padding-left: 2.25rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-image: url(${UiBoy});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

export const AgencySelector = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 35%;
  padding-left: 2.25rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-image: url(${UiCar});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

//
export const Opt = styled.option`
  padding-left: 3rem;
`

export const DeleteInput = styled.img`
  position: absolute;
  right: 4%;
  margin-top: 4.7%;
  height: 0.6rem;
  &:hover {
    cursor: pointer;
  }
`

export const InputDisplayer = styled.div`
  display: flex;
`

export const Success = styled.div`
  position: absolute;
  z-index: 10;
  top: 12rem;
  left: 25rem;
  height: 9rem;
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #FFFCD3;
  font-family: Poppins;
`

export const TextSuccess = styled.div`
  margin-top: 1.5rem;
  font-size: 1.1rem;
`
export const ImgSuccess = styled.img`
  margin-top: 1rem;
  height: 4rem;
`

export const ArrowAgency = styled.img`
  position: absolute; 
  width: 12px;
  top: 7.3%;
  z-index: 10;
  right: 3%;
`
export const ArrowStudent = styled.img`
  position: absolute; 
  width: 12px;
  z-index: 10;
  top: 78%;
  left: 65.5%;
`

export const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
`

export const InfoContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  text-align:center;
  font-size:1.1rem;
  font-weight:400;
  style:normal;
  width:35%;
  margin:auto;
  margin-top:1.3rem;
  border-bottom: 1px solid #979797;
`

export const Info = styled.div`
  height:41px;
  cursor:pointer;
  text-decoration:none;
  flex: 1;
  color:#166DD8;
  &{
    border-bottom:3px solid #166DD8;
  }
`

export const Parcours = styled.div`
  cursor:pointer;
  text-decoration:none;
  color:black;
  flex: 1;
`

export const Stats = styled(Link)`
  cursor:pointer;
  text-decoration:none;
  color:black;
  flex: 1;
`
