import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../../config/configurator'

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Container = styled.div`
margin-top: 2%;
`
export const ActionEdit = styled.div`
  display: flex;
  position:absolute;
  top:20%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;
  margin-left: -4px;
  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const ActionDelete = styled.div`
  margin-left: 8px;
  display: flex;
  position:absolute;
  top:20%;
  left:44%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;

  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`
export const ActionArchive = styled.div`
  margin-left: 8px;
  display: flex;
  position:absolute;
  top:20%;
  left:60%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;

  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const EditIcon = styled.img`
height: 0.8vw;
object-fit: content;
`

export const DeleteIcon = styled.img`
  height: 0.7vw;
`
export const ArchiveIcon = styled.img`
  height: 0.7vw;
`

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left:2rem;
  overflow-x:hidden;
`

export const Progression = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  height: 55px;
`

export const ProgressionBar = styled.div`
  margin-left: -0.7rem;
  width: 80%;
  height: 1.3rem;
  background: white; 
  border-radius: 0.3rem;
  margin-top: -0.4rem;
`

export const ProgressionBarIn = styled.div`
  height: 100%;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, #1AA0FF 0%, #166DD8 95.42%);
`

export const ProgressionText = styled.div`
  width: 25%;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 10px;
`

export const HoverPanel = styled.div`
  position: absolute;
  color: white;
   height: 1.5rem;
   padding-left: 0.3rem;
   padding-right: 0.3rem;
   padding-top: 0.3rem;
   font-size: 0.6rem;
   font-family: Poppins;
   font-weight: 400;
   background-color: #4F4F4F;
   border-radius: 0.3rem;
   z-index: 10;
`

export const TopBar = styled.div`
  display: flex;
  width: 55%;
  padding-left: 7.7%;
  height: 5%;
  margin-top: 3.5%;
  margin-bottom:2%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.1rem;
`

export const EvalBar = styled.div`
  margin-left: 5%;
  border-bottom: solid #53AD49 0.3rem;
`

export const SimulBar = styled.div`
  border-bottom: solid #297FCC 0.3rem;
`

export const FirstContainer = styled.div`
  width: 80vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerFirstStudent = styled.div`
  width: 377px;
  height: 322px;
  display: flex;
  flex-direction: column;
  border: 1px solid #9C9C9D;
  box-sizing: border-box;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`

export const AvatarContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const TextFirstCSV = styled.p`
  text-align: center;
  margin: 10px auto;
  margin-top: 1.3rem;
  width: 85%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
`

export const ButtonImport = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`

export const ImportImg = styled.img`
  width:1rem;
  height:1rem;
  display: flex;
  position: absolute;
  left: 1rem;
`

export const ImportText = styled.div`
  font-size: 16px;
  color: black;
  width: 100%;
  position: absolute;
  left: 2rem;
  margin-left: 8px;
`

export const ButtonCreate = styled(Link)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #979797;
  background-color:#ffffff;
  color:black;
  font-family:Poppins;
  font-style:normal;
  font-size:1rem;
  margin-bottom:1rem;
  line-height:3rem;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
  position: absolute;
`