import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
position:absolute;
left:19.5rem;
top:1.3rem;
`

const Icon = styled.img`
height: 1.5rem;
width: 1.5rem;
`

const BaremEvalIcon = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default BaremEvalIcon
