import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'

const Button = styled.div`
    width:17.31rem;
    height: 3rem;
    background-color:#EFF0F6;
    border-radius:0.6rem;
    margin-top:2rem;
    position:absolute;
    right:0;
    text-align:center;
    line-height:3rem;
    color:#868383;
    font-family:Poppins;
    font-weight:500;
    font-style:normal;
    font-size:1rem;
    cursor:pointer;

`

const Buttonmodif = (props) => {
  return (
    <Button>{props.text}</Button>
  )
}

export default Buttonmodif
