import styled from 'styled-components'

// General composent
export const Bg = styled.div`
  display:flex; 
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const MainLayout = styled.div`
  display: flex;
  width:85%; 
  flex-direction: column;
  height: 100vh;
  background-color: white;
  align-items: center;
  justify-content: center;
`

// Content part

export const ContentContainer = styled.div`
  width: 35%;
`

// Scrolling menu components
export const ScrollingContainer = styled.div`
  dislay:flex;
  position: relative;
  height: 2.5rem;
  align-items: center;
  border: 1px solid #B3ABBC;
  border-radius: 10px;
  margin-top:3rem;
  margin-bottom: 3rem;
`

export const StudentName = styled.div`
  width:20.25rem;
  font-size:1rem;
  font-weight:300;
  font-family:Poppins;
`

export const BlocTitle = styled.div`
  width:16.3rem;
  height:1.75rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  width:6rem;
  color: white;
  font-family:Poppins;
  width: auto;
  text-align:center;
  margin-top: 1rem; 
  margin-bottom: 1rem;
`

// Main rates elements
export const StatsMainBloc = styled.div`
  display: flex;
  height: 10rem;
  justify-content: space-between
`

export const StatsBloc = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  background:${props => props.color1};
  background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2});
  margin-left:0rem;
  align-items:center;
  border:1px solid #979797;
  border-radius: 10px;
`

// Details rates

export const StatsItemContainer = styled.div`
  height: 3.5rem; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
`

export const StatsContainer = styled.div`
  height: 35rem;
  margin-top:1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const StatsRow = styled.div`
  height:100%;
  width : 80%;
  display: flex;
  align-items:center;
  border: 1px solid #979797;
  border-radius: 10px;
`

export const Text = styled.div`
position:static;
width:23.4rem;
height:1.5rem;
left:1rem;
line-height:1.5rem;
font-size: 1.125rem;
font-family: Poppins;
font-weight: 500;
font-style:normal;
padding-left:3rem;
text-align: left;
align-items:center;
display:flex;
`

export const Contour = styled.span`
  width: 5rem;
  height:3.5rem;
  display: flex;
  border-radius: 10px;
  background-color: #EBEFF7;
  color: #024179;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
`

export const StatsGrey = styled.span`
  color: #024179;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 300;
  padding-left: 0.1rem;
  line-height: 2.1rem;
`

export const Score = styled.div`
  color: #024179;
  font-style: normal;
  font-size: 2.1rem;
  padding-top:0rem;
  font-weight: 600;
`

export const ContourNote = styled.div`
  display: flex;
  align-items: flex-end;
`

export const ScoreEval = styled.div`
color: white;
font-style: normal;
font-size: 4.68rem;
line-height: 4rem; 
font-family: Poppins;
font-weight:bold;
`

export const ScoreNote = styled.div`
  color: white;
  font-style: normal;
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 300;
  margin-left: 0.8rem;
`

// Printer components
export const PrintContainer = styled.div`
  position: relative;
  display: flex;
  height:3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Print = styled.button`
position:absolute;
display: flex;
justify-content: space-around; 
align-items: center;  
width:8.1rem;
height: 100%;
right: 0; 
font-family: Poppins;
font-size: 1rem;
font-weight: 500;
font-style:normal;
line-height:1rem;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
border:1px solid #979797;
border-radius: 0.5rem;
cursor:pointer;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
`
