
/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './GridOverload.css'
import '../../fonts/fonts.css'
import ModalConfirm from '../../components/ModalConfirm'
import { deleteAgencySaga, refreshAgenciesSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deleteAgency, refreshAgencies } from '../App/actions'
import ShouldRefreshAgencies from '../ShouldRefreshAgencies/index'
import AgencyAvatar from '../../images/firstAgency.svg'
import AddStudent from '../../images/Add.svg'
import config from '../../config/configurator'
import { ActionContainer, ActionEdit, ActionArchive, DeleteIcon, EditIcon, Bg, FirstContainer, ContainerFirstAgency, TextFirstCSV, AvatarContainer, ButtonImport, ButtonCreate, ImportImg, ImportText, Container, TotalStudents } from './styledComponents'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import UiEdit from '../../images/edit.svg'
import UiDelRed from '../../images/closeRed.svg'

const Agency = () => {
  const dispatch = useDispatch()

  const agencies = useSelector((state) => state.toJS().global.agencies)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const players = useSelector((state) => state.toJS().global.players)
  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)

  let numOfStudents = 0
  // const [showPanel, setShowPanel] = useState() // FIXME: use react-tooltip ?
  const [currentAgencyId, setCurrentAgencyId] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalName, setModalName] = useState('')

  useEffect(() => {
    dispatch(refreshAgencies(token))
    // eslint-disable-next-line
  }, [])

  function confirmDelete (id, action, agency) {
    setShowModal(true)
    setModalText(`${action}`)
    setModalName(agency)
    setCurrentAgencyId(id)
  }

  const deleteCurrAgency = (id) => {
    dispatch(deleteAgency(token, id))
  }

  const buildRows = () => {
    const rowData = []
    if (!agencies) { return null }
    agencies.forEach((agency) => {
      const agencyPlayers = config.features.simu ? simuPlayers.reduce((a, v) => (v.agencyId === agency.id ? a + 1 : a), 0) : players.reduce((a, v) => (v.agencyId === agency.id ? a + 1 : a), 0)
      numOfStudents = numOfStudents + agencyPlayers
      rowData.push({
        agencyName: agency.agencyname,
        agencyId: agency.id,
        players: agencyPlayers,
        creationDate: moment(agency.date).format('DD/MM/YYYY')
      })
    })
    return rowData
  }
  const rowHeight = 55
  const gridOptions = {
    defaultColDef: { filter: true },
    columnDefs: [
      { width:253,suppressMovable: true, headerName: 'Agences', field: 'agency', cellClass:'rowGreyBackgroundwithAgencyImg', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{lineHeight:'1.5rem',paddingLeft:'3rem',textOverflow: 'ellipsis', overflow: 'hidden',}} to={{ pathname: `agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>{params.data.agencyName}</Link> } },
      { width:145,suppressMovable: true, headerName: 'Eleves', field: 'players', cellClass:'rowGreyBackground', suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true } },
      { width:145,suppressMovable: true, headerName: 'Date', field: 'creationDate', cellClass:'rowGreyBackground', suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true } },
      {
        suppressMovable: true,
        width: 88,
        suppressMenu: true,
        cellClass:'rowGreyBackground',
        field: 'Actions',
        cellRendererFramework: (params) => {
          return (
            <ActionContainer>
              <Link to={`agency/edit/${params.data.agencyId}`}>
                <ActionEdit>
                  <EditIcon src={UiEdit} />
                </ActionEdit>
              </Link>
              {/* <ActionArchive onMouseEnter={() => { showPanel(true, 'Supprimer') }} onMouseLeave={() => showPanel(false, '')} onClick={() => confirmDelete(id, 'Vous allez supprimer', name)}> */}
              <ActionArchive onClick={() => confirmDelete(params.data.agencyId, 'Supprimer', params.data.agencyName)}>
                <DeleteIcon
                  src={UiDelRed}
                />
              </ActionArchive>
            </ActionContainer>
          )
        }
      }
    ]
  }

  if ((agencies && (players || simuPlayers)) == null) {
    return (<div>LOADING</div>)
  }

  const hasAgency = agencies.length > 0
  return (
    <Bg>
      <ShouldRefreshAgencies token={token} />
      <ModalConfirm show={showModal} name={modalName} text={modalText} confirm={() => { deleteCurrAgency(currentAgencyId); setShowModal(false) }} cancel={() => setShowModal(false)} />
      {!hasAgency
        ? (
          <FirstContainer>
            <ContainerFirstAgency>
              <AvatarContainer>
                <img alt='' src={AgencyAvatar} />
              </AvatarContainer>
              <TextFirstCSV>
                Vous pouvez <b>créer une agence</b> avec votre console de gestion
              </TextFirstCSV>
              <ButtonImport>
                <ButtonCreate to='/agency/create' style={{ width: '201px' }}>
                  <ImportImg src={AddStudent} />
                  <ImportText>Créer une agence</ImportText>
                </ButtonCreate>
              </ButtonImport>
            </ContainerFirstAgency>
          </FirstContainer>)
        : (
          <Container className='agencygrid'>
            <div className='ag-theme-alpine-agency' style={{ height: 600, width: 702 }}>
              <AgGridReact
                rowData={buildRows()}
                gridOptions={gridOptions}
                rowHeight={rowHeight}
              />
            </div>
            <TotalStudents>Nombre total d'élèves: {numOfStudents}</TotalStudents>
          </Container>
          )}
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'deleteAgency', saga: deleteAgencySaga })
const withSagaRefreshAg = injectSaga({ key: 'refreshAgencies', saga: refreshAgenciesSaga })
const arg = compose(withSaga, withSagaRefreshAg, withConnect)(Agency)

export default arg
