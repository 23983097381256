/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_AGENCY, REFRESH_AGENCIES } from '../App/constants'
import { deleteAgencySuccess, deleteAgencyError, refreshAgenciesSuccess, refreshAgenciesError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * deleteAgency ({ token, id }) {
  console.log(token, id, 'DELETE')
  const urlAgencyDelete = `${serverPath}/api/agencies/${id}?access_token=${token}`
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`
  console.log(urlAgencyDelete)

  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlAgencyDelete, { method: 'DELETE' })
    const agencies = yield call(request, urlAgencies, { method: 'GET' })
    yield put(deleteAgencySuccess(agencies))
  } catch (err) {
    yield put(deleteAgencyError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteAgencySaga () {
  yield takeLatest(DELETE_AGENCY, deleteAgency)
}

export function * refreshAgencies ({ token }) {
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`

  try {
    const agencies = yield call(request, urlAgencies, { method: 'GET' })
    yield put(refreshAgenciesSuccess(agencies))
  } catch (err) {
    yield put(refreshAgenciesError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshAgenciesSaga () {
  yield takeLatest(REFRESH_AGENCIES, refreshAgencies)
}
