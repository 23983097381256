import styled from 'styled-components'
import config from '../../config/configurator'
import backgroundImage from '../../imagesMB/fond.jpg'

const configColors = config.colors.bgSideBar

export const Bg = styled.div`
  text-align:center;
  height: 100%;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`

export const VerticalContainer = styled.div`
  height: 100vh;
  margin: auto;
  width: 26.25rem;
  text-align: center;
  align-items: center;
  background-color: ${configColors};
  background-position: bottom 2% left 50%;
  background-size: 60%;
  position: relative;
`

export const LogoImg = styled.img`
  width: 205px;
  margin-top: 4.5rem;
  cursor: pointer;
  margin-bottom: 0.75rem;
`

export const Title = styled.div`
  font-family:Poppins;
  font-weight:300;
  font-style:normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color:white;
  margin-bottom: 265px;
`

export const Input = styled.input`
  display: block;
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 2px #dadada;
  width: 16.87rem;
  height: 2.3rem;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 0.6rem;
  padding-left: 1rem;
  font-size: 1rem;
  &:focus {
    outline: none;
    border: 3px solid #00c4f3;
  }
`

export const Button = styled.div`
  z-index: 10;
  width: 7.5rem;
  height: 3rem;
  border-radius: 10px;
  background-color:#61D7F3;
  text-align: center;
  color: black;
  margin-left:14rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TextButton = styled.p`
  font-family: Poppins;
  font-style: medium;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 24px;
`

export const Id = styled.div`
  font-family:Poppins;
  font-style:normal;
  font-weight:400;
  font-size:0.75rem;
  text-align:left;
  color:white;
  margin-bottom: 0.5rem;
  margin-left:5.5rem;
  outline: unset;
`

export const Mdp = styled.div`
  font-family:Poppins;
  font-style:normal;
  font-weight:400;
  font-size:0.75rem;
  text-align:left;
  color:white;
  margin-bottom: 0.5rem;
  margin-left:5.5rem;
  margin-top:1.5rem;
`
export const LegalMention = styled.img`
  position: absolute;
  bottom: 2%;
  left: 16%;
`

export const FeedBack = styled.div`
  color: red;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: 2rem;
`
export const BottomLogoContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
`

export const BottomLogo = styled.img`
  width: 5.625rem;
`

export const LegalMentionText = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  color: white;
  margin-top: 18px;
`
