import React from 'react'
import UiWarn from '../../images/warning.svg'
import { Bg, Container, ContentText, Title, Icon, Name, ContentBox, ButtonAction, ButtonCancel } from './styledComponents'

const ModalConfirm = (props) => {
  const bText = props.confirmText ? props.confirmText : 'Confirmer'
  if (!props.show) {
    return (<div />)
  }

  return (
    <Bg>
      <Container style={{ width: props.text === 'Vous allez vous déconnecter' ? '18%' : '16.5%', height: props.text === 'Vous allez vous déconnecter' ? '16%' : '18.33%' }}>
        <ContentText>
          <Title><Icon src={UiWarn} />{props.text}</Title>
          <Name>{props.name}</Name>
        </ContentText>
        <ContentBox>
          {props.noCancel === undefined
            ? <ButtonCancel onClick={() => props.cancel()}>
              Annuler
            </ButtonCancel>
            : null}
          <ButtonAction style={{ width: props.confirmText === 'Se déconnecter' ? '143px' : '33.75%' }} onClick={() => props.confirm()}>
            {bText}
          </ButtonAction>
        </ContentBox>
      </Container>
    </Bg>
  )
}

export default ModalConfirm
