import {
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_ERROR,
  GET_TOKEN_AGENCY,
  GET_TOKEN_AGENCY_SUCCESS,
  GET_TOKEN_AGENCY_ERROR,
  GET_AGENCYTOKEN,
  GET_AGENCYTOKEN_SUCCESS,
  CREATE_PLAYER,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_ERROR,
  CREATE_EVAL_FOR_PLAYER,
  CREATE_EVAL_FOR_PLAYER_SUCCESS,
  CREATE_EVAL_FOR_PLAYER_ERROR,
  AGENCY_LOGIN,
  AGENCY_LOGIN_SUCCESS,
  AGENCY_LOGIN_ERROR,
  AGENCY_CHECK_TOKEN,
  AGENCY_CHECK_TOKEN_SUCCESS,
  AGENCY_CHECK_TOKEN_ERROR,
  AGENCY_LOGIN_RESET,
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_ERROR,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_ERROR,
  DELETE_PLAYER,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_ERROR,
  DELETE_AGENCY,
  DELETE_AGENCY_SUCCESS,
  DELETE_AGENCY_ERROR,
  DELETE_SIMU_PLAYER,
  DELETE_SIMU_PLAYER_SUCCESS,
  DELETE_SIMU_PLAYER_ERROR,
  SIMU_PLAYER_EDIT,
  SIMU_PLAYER_EDIT_SUCCESS,
  SIMU_PLAYER_EDIT_ERROR,
  PLAYER_EDIT,
  PLAYER_EDIT_SUCCESS,
  PLAYER_EDIT_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_AGENCY_PASSWORD,
  CHANGE_AGENCY_PASSWORD_SUCCESS,
  CHANGE_AGENCY_PASSWORD_ERROR,
  REFRESH_PLAYERS,
  REFRESH_PLAYERS_ERROR,
  REFRESH_PLAYERS_SUCCESS,
  REFRESH_AGENCIES,
  REFRESH_AGENCIES_ERROR,
  REFRESH_AGENCIES_SUCCESS,
  REFRESH_SIMU_PLAYERS,
  REFRESH_SIMU_PLAYERS_ERROR,
  REFRESH_SIMU_PLAYERS_SUCCESS,
  GET_TOKEN_SIMU,
  GET_TOKEN_SIMU_SUCCESS,
  GET_TOKEN_SIMU_ERROR,
  GET_SIMU_PLAYERS,
  GET_SIMU_PLAYERS_SUCCESS,
  GET_SIMU_PLAYERS_ERROR,
  SET_SCALE_EVAL,
  SET_SCALE_EVAL_SUCCESS,
  SET_SCALE_EVAL_ERROR,
  VERIF_EMAIL,
  VERIF_EMAIL_SUCCESS,
  VERIF_EMAIL_ERROR,
  LOAD_JOURNEYS,
  LOAD_JOURNEYS_SUCCESS,
  LOAD_JOURNEYS_ERROR,
  AGENCY_EDIT,
  AGENCY_EDIT_SUCCESS,
  AGENCY_EDIT_ERROR,
  RESET
} from './constants'

export function changeAgencyPassword (credentials) {
  return {
    type: CHANGE_AGENCY_PASSWORD,
    credentials
  }
}

export function changeAgencyPasswordSuccess (token) {
  return {
    type: CHANGE_AGENCY_PASSWORD_SUCCESS,
    token
  }
}

export function changeAgencyPasswordError (error) {
  return {
    type: CHANGE_AGENCY_PASSWORD_ERROR,
    error
  }
}

export function changePassword (credentials) {
  return {
    type: CHANGE_PASSWORD,
    credentials
  }
}

export function changePasswordSuccess (token) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    token
  }
}

export function changePasswordError (error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  }
}

export function simuPlayerEdit (credentials) {
  return {
    type: SIMU_PLAYER_EDIT,
    credentials
  }
}

export function simuPlayerEditSuccess (player) {
  return {
    type: SIMU_PLAYER_EDIT_SUCCESS,
    player
  }
}

export function simuPlayerEditError (error) {
  return {
    type: SIMU_PLAYER_EDIT_ERROR,
    error
  }
}
export function playerEdit (credentials) {
  return {
    type: PLAYER_EDIT,
    credentials
  }
}

export function playerEditSuccess (player) {
  return {
    type: PLAYER_EDIT_SUCCESS,
    player
  }
}

export function playerEditError (error) {
  return {
    type: PLAYER_EDIT_ERROR,
    error
  }
}

export function refreshAgencies (token) {
  return {
    type: REFRESH_AGENCIES,
    token
  }
}

export function refreshAgenciesSuccess (agencies) {
  return {
    type: REFRESH_AGENCIES_SUCCESS,
    agencies
  }
}

export function refreshAgenciesError (error) {
  return {
    type: REFRESH_AGENCIES_ERROR,
    error
  }
}

export function refreshSimuPlayers (token, id, isAdmin) {
  return {
    type: REFRESH_SIMU_PLAYERS,
    token,
    id,
    isAdmin
  }
}

export function refreshSimuPlayersSuccess (simuPlayers) {
  return {
    type: REFRESH_SIMU_PLAYERS_SUCCESS,
    simuPlayers
  }
}

export function refreshSimuPlayersError (error) {
  return {
    type: REFRESH_SIMU_PLAYERS_ERROR,
    error
  }
}

export function refreshPlayers (token, id, isAdmin) {
  return {
    type: REFRESH_PLAYERS,
    token,
    id,
    isAdmin
  }
}

export function refreshPlayersSuccess (players) {
  return {
    type: REFRESH_PLAYERS_SUCCESS,
    players
  }
}

export function refreshPlayersError (error) {
  return {
    type: REFRESH_PLAYERS_ERROR,
    error
  }
}

export function getPlayers (token, id, isAdmin) {
  return {
    type: GET_PLAYERS,
    token,
    id,
    isAdmin
  }
}

export function getPlayersSuccess (players, progressions, sequencesRaw) {
  return {
    type: GET_PLAYERS_SUCCESS,
    players,
    progressions,
    sequencesRaw
  }
}

export function getPlayersError (error) {
  return {
    type: GET_PLAYERS_ERROR,
    error
  }
}

export function getAgencies (token) {
  return {
    type: GET_AGENCIES,
    token
  }
}

export function getAgenciesSuccess (agencies) {
  return {
    type: GET_AGENCIES_SUCCESS,
    agencies
  }
}

export function getAgenciesError (error) {
  return {
    type: GET_AGENCIES_ERROR,
    error
  }
}

export function agencyEdit (credentials) {
  return {
    type: AGENCY_EDIT,
    credentials
  }
}

export function agencyEditSuccess (agency) {
  return {
    type: AGENCY_EDIT_SUCCESS,
    agency
  }
}

export function agencyEditError (error) {
  return {
    type: AGENCY_EDIT_ERROR,
    error
  }
}

export function getSimuPlayers (token, id, isAdmin) {
  return {
    type: GET_SIMU_PLAYERS,
    token,
    id,
    isAdmin
  }
}

export function getSimuPlayersSuccess (simuPlayers) {
  return {
    type: GET_SIMU_PLAYERS_SUCCESS,
    simuPlayers
  }
}

export function getSimuPlayersError (error) {
  return {
    type: GET_SIMU_PLAYERS_ERROR,
    error
  }
}

export function deletePlayer (token, id, agencyid) {
  return {
    type: DELETE_PLAYER,
    token,
    id,
    agencyid
  }
}

export function deletePlayerSuccess (players) {
  return {
    type: DELETE_PLAYER_SUCCESS,
    players
  }
}
export function deletePlayerError (error) {
  return {
    type: DELETE_PLAYER_ERROR,
    error
  }
}

export function deleteSimuPlayer (token, id, agencyid) {
  return {
    type: DELETE_SIMU_PLAYER,
    token,
    id,
    agencyid
  }
}

export function deleteSimuPlayerSuccess (players) {
  return {
    type: DELETE_SIMU_PLAYER_SUCCESS,
    players
  }
}
export function deleteSimuPlayerError (error) {
  return {
    type: DELETE_SIMU_PLAYER_ERROR,
    error
  }
}

export function deleteAgency (token, id) {
  return {
    type: DELETE_AGENCY,
    token,
    id
  }
}

export function deleteAgencySuccess (agencies) {
  return {
    type: DELETE_AGENCY_SUCCESS,
    agencies
  }
}

export function deleteAgencyError (error) {
  return {
    type: DELETE_AGENCY_ERROR,
    error
  }
}

export function agencyLogin (credentials) {
  return {
    type: AGENCY_LOGIN,
    credentials
  }
}

export function agencyLoginsuccess (agencytoken, agencyid, isAdmin, evalScale, agencyname) {
  return {
    type: AGENCY_LOGIN_SUCCESS,
    agencytoken,
    agencyid,
    isAdmin,
    evalScale,
    agencyname
  }
}

export function agencyLoginerror (error) {
  return {
    type: AGENCY_LOGIN_ERROR,
    error
  }
}

export function agencyCheckToken (token) {
  return {
    type: AGENCY_CHECK_TOKEN,
    token
  }
}

export function agencyCheckTokensuccess (agencytoken, agencyid, isAdmin, evalScale, agencyname) {
  return {
    type: AGENCY_CHECK_TOKEN_SUCCESS,
    agencytoken,
    agencyid,
    isAdmin,
    evalScale,
    agencyname
  }
}

export function agencyCheckTokenerror (error) {
  return {
    type: AGENCY_CHECK_TOKEN_ERROR,
    error
  }
}

export function agencyLoginReset (error) {
  return {
    type: AGENCY_LOGIN_RESET,
    error
  }
}

export function getAgencyToken (agencyname, agencypassword) {
  return {
    type: GET_AGENCYTOKEN,
    agencyname,
    agencypassword
  }
}

export function getagencytokensuccess (agencytoken) {
  return {
    type: GET_AGENCYTOKEN_SUCCESS,
    agencytoken
  }
}

export function getToken (credentials) {
  return {
    type: GET_TOKEN,
    credentials
  }
}

export function getTokenSuccess (token) {
  return {
    type: GET_TOKEN_SUCCESS,
    token
  }
}

export function getTokenError (error) {
  return {
    type: GET_TOKEN_ERROR,
    error
  }
}

export function getTokenSimu (credentials) {
  return {
    type: GET_TOKEN_SIMU,
    credentials
  }
}

export function getTokenSimuSuccess (token) {
  return {
    type: GET_TOKEN_SIMU_SUCCESS,
    token
  }
}

export function getTokenSimuError (error) {
  return {
    type: GET_TOKEN_SIMU_ERROR,
    error
  }
}

export function getTokenAgency (name, password, token, logo, email) {
  return {
    type: GET_TOKEN_AGENCY,
    name,
    password,
    token,
    logo,
    email
  }
}

export function getTokenAgencySuccess (token) {
  return {
    type: GET_TOKEN_AGENCY_SUCCESS,
    token
  }
}

export function getTokenAgencyError (error) {
  return {
    type: GET_TOKEN_AGENCY_ERROR,
    error
  }
}

export function setScaleEval (scale, id, token) {
  return {
    type: SET_SCALE_EVAL,
    scale,
    id,
    token
  }
}

export function setScaleEvalSuccess (scale) {
  return {
    type: SET_SCALE_EVAL_SUCCESS,
    scale
  }
}

export function setScaleEvalError (error) {
  return {
    type: SET_SCALE_EVAL_ERROR,
    error
  }
}

export function verifEmail (email) {
  return {
    type: VERIF_EMAIL,
    email
  }
}

export function verifEmailSuccess (email) {
  return {
    type: VERIF_EMAIL_SUCCESS,
    email
  }
}

export function verifEmailError (error) {
  return {
    type: VERIF_EMAIL_ERROR,
    error
  }
}

export function createPlayer (player) {
  return {
    type: CREATE_PLAYER,
    player
  }
}

export function createPlayerSuccess (player) {
  return {
    type: CREATE_PLAYER_SUCCESS,
    player
  }
}

export function createPlayerError (error) {
  return {
    type: CREATE_PLAYER_ERROR,
    error
  }
}

export function createEvalForPlayer (player) {
  return {
    type: CREATE_EVAL_FOR_PLAYER,
    player
  }
}

export function createEvalForPlayerSuccess (player) {
  return {
    type: CREATE_EVAL_FOR_PLAYER_SUCCESS,
    player
  }
}

export function createEvalForPlayerError (error) {
  return {
    type: CREATE_EVAL_FOR_PLAYER_ERROR,
    error
  }
}

export function logoutreset () {
  return {
    type: RESET
  }
}

export function loadJourneys (token) {
  return {
    type: LOAD_JOURNEYS,
    token
  }
}

export function journeysLoaded (journeys) {
  return {
    type: LOAD_JOURNEYS_SUCCESS,
    journeys
  }
}

export function journeysLoadingError (error) {
  return {
    type: LOAD_JOURNEYS_ERROR,
    error
  }
}
