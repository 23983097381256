import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { useLocation, useHistory } from 'react-router-dom'
import { agencyEditData, deleteAgencySaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { agencyEdit, deleteAgency } from '../App/actions'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import Pen from '../../images/pen.svg'
import ButtonUploadAws from '../../components/ButtonUploadAws'
import SuccessIcon from '../../images/Icone_agence.svg'
import UiDanger from '../../images/UiWarn.svg'
import ModalConfirm from '../../components/ModalConfirm'
import Arrow from '../../images/ArrowDownSelector.svg'
import { Selector, ArrowDown, Opt, Bg, MainLayout, Success, ImgSuccess, TextSuccess, SelectorContainer, FormAndLogoContainer, FormContainer, BoxContainer, Name, Input, DeleteInput, FeedBack, ButtonContainer, Button, DeleteButton, UploadContainer, UploadInput, UploadTitle } from './styledComponents'
import config from '../../config/configurator'

const AgencyEdit = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const agency = agencies ? agencies.find((a) => a.id.toString() === id) : ''

  const [agencyId, setAgencyId] = useState(agency.id)
  const [agencyPassword, setAgencyPassword] = useState('')
  const [repeatPassword, setRepeatPassowrd] = useState('')
  const [agencyName, setAgencyName] = useState(agency.agencyname)
  const [wrongRepeat, setWrongRepeat] = useState(false)
  const [errorCode, setErrorCode] = useState(0)
  const [email, setEmail] = useState(agency.email || agency.notificationEmail)
  const [logo, setLogo] = useState()
  const [enable, setEnable] = useState()
  // const [delName, setDelName] = useState(false) /* TODO: See when we add the delete icon for empty inputs */
  // const [delMail, setDelMail] = useState(false)
  // const [delPsw, setDelPsw] = useState()
  // const [delRep, setDelRep] = useState()
  const [warnMail, setWarnMail] = useState()
  const [backgroundColor, setBackgroundColor] = useState()
  const [color, setColor] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState()
  const [borderColorMail, setBorderColorMail] = useState()

  useEffect(() => {
    checkPassword()
    checkError()
    setAgencyId(agency.id)
    setAgencyName(agency.agencyname)
    // eslint-disable-next-line
  }, [agency.id, agencyPassword, repeatPassword])

  const checkPassword = () => {
    if (agencyPassword && repeatPassword) {
      if (agencyPassword !== repeatPassword) {
        setWrongRepeat(true)
        setBackgroundColor('#EFF0F6')
        setColor('#868383')
        setEnable(false)
      } else if (agencyPassword && repeatPassword && (agencyPassword === repeatPassword)) {
        setWrongRepeat(false)
        setBackgroundColor('#00C4F3')
        setColor('black')
        setEnable(true)
      }
      if (agencyPassword === repeatPassword) {
        setWrongRepeat(false)
      }
    }
  }

  const checkError = () => {
    if (error !== undefined) {
      if (error.response !== undefined) {
        if (errorCode !== error.response.status) {
          setErrorCode(error.response.status)
        }
      }
    }
  }

  function verification () {
    // Look if something change in the form.
    if (agencyName) {
      if (!wrongRepeat) {
      // Check if the mail format is valid
        if (emailCheck()) {
          setBackgroundColor('#00C4F3')
          setColor('black')
          setEnable(true)
        } else {
          setBackgroundColor('#EFF0F6')
          setColor('#868383')
          setEnable(false)
        }
      }
    } else {
      setBackgroundColor('#868383')
      setColor('#868383')
      setEnable(false)
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickHandlerAccount()
    }
  }

  function clickHandlerAccount () {
    dispatch(agencyEdit({
      id: agencyId,
      agencyname: agencyName,
      email: email,
      password: agencyPassword,
      token: agencyToken,
      logo: logo
    }))
    setErrorCode(200)
    setBackgroundColor('#EFF0F6')
    setColor('#868383')
    setEnable(false)
    setAgencyPassword('')
    setRepeatPassowrd('')

    setTimeout(() => {
      setErrorCode(0)
    }, 2000)
  }

  function confirmDelete (id, action) {
    setShowModal(true)
    setModalText(`${action}`)
  }

  const deleteCurrentAgency = (id) => {
    dispatch(deleteAgency(agencyToken, id))
    history.push('/agencies')
  }

  function handleChangeAgency (e) {
    history.push(`/agency/edit/${e.target.value}`)
    const newAgency = agencies.find(ag => ag.id.toString() === e.target.value)
    setAgencyId(e.target.value)
    setAgencyName(newAgency.agencyname)
    setEmail(newAgency.notificationEmail || newAgency.email)
    setLogo(newAgency.logo)
    setBackgroundColor('#EFF0F6')
    setColor('#868383')
    setEnable(false)
    if (isAdmin) {
      setAgencyId(newAgency.agencyId)
    }
  }

  function displayAgencySelector () {
    if (agency) {
      return (
        <div>
          <Selector style={{ width: config.title === 'ML ADMIN' ? '35rem' : '33.125rem' }} onChange={(e) => { handleChangeAgency(e) }} value={agencyId}>
            <ArrowDown src={Arrow} />
            {agencies.map((ag, i = 0) => {
              return <Opt key={i} value={ag.id}>{ag.agencyname}</Opt>
            })}
          </Selector>
        </div>
      )
    }
  }

  function emailCheck () {
    if (!email) {
      setBorderColorMail('#B3ABBC')
      setWarnMail(false)
      return true
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null) {
      setBorderColorMail('#FF0000')
      setWarnMail(true)
      return false
    }
    setBorderColorMail('#B3ABBC')
    setWarnMail(false)
    return true
  }
  return (
    <Bg>
      <SideBar />
      <MainLayout>
        {errorCode === 200
          ? <Success>
            <ImgSuccess src={SuccessIcon} />
            <TextSuccess>{agencyName} a été modifiée.</TextSuccess>
          </Success>
          : null}
        <MainTitle text='Le compte agence' icon={Pen} />
        <ModalConfirm show={showModal} confirmText='Supprimer' text={modalText} name={agencyName} confirm={() => deleteCurrentAgency(agencyId)} cancel={() => setShowModal(false)} />
        <SelectorContainer>
          <ArrowDown src={Arrow} />
          {displayAgencySelector()}
        </SelectorContainer>
        <FormAndLogoContainer style={{ justifyContent: config.layouts.agencyCreate && (config.layouts.agencyCreate.logo === false) ? 'center' : 'unset' }}>
          <FormContainer style={{ marginLeft: config.layouts.agencyCreate && (config.layouts.agencyCreate.logo === false) ? '0' : '17.8rem' }}>
            <BoxContainer>
              <Name>Nom de l'agence</Name>
              <Input
                value={agencyName}
                onChange={(e) => { setAgencyName(e.target.value); verification() }}
              />
              {/* {delAgencyName && agencyName ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => emptyInput('name')} />) : ''} */}
            </BoxContainer>
            {config.title !== 'ML ADMIN'
              ? <BoxContainer>
                <Name>Email</Name>
                <Input
                  value={email}
                  type='text'
                  style={{ borderColor: borderColorMail }}
                  onChange={(e) => { setEmail(e.target.value); emailCheck(); verification() }}
                />
                {/* {delMail && email ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('email'); setState({ borderColorMail: '#B3ABBC', warnMail: false })}} />) : ''} */}
                {(warnMail || errorCode === 409) && /*! delMail && */ email ? (<DeleteInput style={{ bottom: '45%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : ''}
                <FeedBack style={{ opacity: warnMail ? '1' : '0' }}>
                  Email non valide
                </FeedBack>
                </BoxContainer>
              : null}
            <BoxContainer>
              <Name>Mot de passe</Name>
              <Input
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                value={agencyPassword}
                type='text'
                onChange={(e) => { setAgencyPassword(e.target.value); verification() }}
              />
              {/* {delPsw && agencyPassword ? ( <DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('agencyPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
              {wrongRepeat/*  && !delPsw */ ? (<DeleteInput style={{ bottom: '45%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </BoxContainer>
            <BoxContainer>
              <Name>Répéter le mot de passe</Name>
              <Input
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyPress={onKeyPress}
                value={repeatPassword}
                type='text'
                onChange={(e) => { setRepeatPassowrd(e.target.value); verification() }}
              />
              {/* {delRep && repeatPassword ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('repeatPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
              {wrongRepeat /* && !delRep */ ? (<DeleteInput style={{ bottom: '44%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
              <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
                Les mots de passes ne correspondent pas
              </FeedBack>
            </BoxContainer>
            <ButtonContainer>
              <DeleteButton
                onClick={() => confirmDelete(agencyId, 'Vous allez supprimer', agencyName)}
              >
                Supprimer
              </DeleteButton>
              <Button
                disabled={!enable}
                data-type='premium'
                style={{ backgroundColor: backgroundColor, color: color, cursor: enable ? 'pointer' : 'unset' }}
                onClick={() => clickHandlerAccount()}
              >
                Modifier l'agence
              </Button>
            </ButtonContainer>
          </FormContainer>
          {config.layouts.agencyCreate.logo === true
            ? <UploadInput>
              <UploadContainer>
                <UploadTitle>Ajouter / Modifier un logo</UploadTitle>
                {/* This pop up will show if we change media type while the previous media is still on aws */}
                {/* {showPopUpWarning && (
          <PopUp
            text='Supprimez d abord l ancien media'
            textNO='blah ok'
            callbackNo={() => setState({ showPopUpWarning: false })}
          />
        )} */}
                <ButtonUploadAws
                  prevFilename={logo}
                  onUpload={(fileName) => {
                    setLogo(fileName)
                    setBackgroundColor('#00C4F3')
                    setColor('black')
                    setEnable(true)
                  }}
                  onDelete={() => {
                    setLogo(null)
                    setBackgroundColor('#00C4F3')
                    setColor('black')
                    setEnable(true)
                  }}
                  folder='agencyLogos/'
                  width='21.3rem'
                  height='12.1rem'
                />
              </UploadContainer>
              </UploadInput>
            : null}
        </FormAndLogoContainer>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'AgencyEdit', saga: agencyEditData })
const withDeleteSaga = injectSaga({
  key: 'AgencyDelete',
  saga: deleteAgencySaga
})
const arg = compose(withSaga, withDeleteSaga, withConnect)(AgencyEdit)

export default arg
