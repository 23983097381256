import React from 'react'
import styled from 'styled-components'
import Uiplayer from '../../images/Student.png'
import IconPlayers from '../../components/IconPlayers'

const Container = styled.div`
`

const Icon = () => {
  return (
    <Container>
      <IconPlayers icon={Uiplayer} />
    </Container>
  )
}

export default Icon
