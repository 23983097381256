import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding-left:1rem;
padding-right:1rem;
`

const Icon = styled.img`
object-fit: cover; 
`

const Iconplayer = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default Iconplayer
