import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import BaremEvalIcon from '../../components/BaremEvalIcon'
import UiBaremEval from '../../images/BaremArrow.svg'

const Bar = styled.div`
margin-top:3.5rem;
margin-left:-0.5rem;
width:15rem;
text-align:left;
font-style:normal;
font-size:2.5rem;
font-weight:600;
font-family:Poppins;
color:black;
line-height:1.25rem;
display:flex;
`

const Title = styled.div`
  font-size:1.8rem;
  font-style:normal;
  font-weight:600;
  font-family:Poppins;
  line-height:1.1rem;
  width:16.5rem;
  height:1.25rem;
  z-index:2;
  position:absolute;
  top:2.45rem;
  left:22.5rem;
`
const Hline = styled.div`
 border:1px solid #B3ABBC;
 width:105.25rem;
 height:0rem;
 position:absolute;
 top:5.5rem;
 left:20rem;
`

const BaremEval = () => {
  return (
    <Bar>
      <BaremEvalIcon icon={UiBaremEval} />
      <Title>Barème Eval</Title>
      <Hline />
    </Bar>
  )
}

export default BaremEval
