
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getTokenSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getToken } from '../App/actions'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import AddStudent from '../../images/AddStudent.svg'
import SuccessIcon from '../../images/successface.svg'
import UiDanger from '../../images/UiWarn.svg'
import ArrowDown from '../../images/ArrowDownSelector.svg'
import { Selector, Opt, FormContainer, BoxContainer, BoxTitle, Input, DeleteInput, FeedBack, Arrow, Bg, SelectorContainer, Button, MainLayout, ImgSuccess, Container, TextSuccess, Success } from './styledComponents'

const PlayerCreate = () => { // eslint-disable-line react/prefer-stateless-function
  const dispatch = useDispatch()

  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)
  const loading = useSelector((state) => state.toJS().global.loading)
  const journeys = useSelector((state) => state.toJS().global.journeys)

  const [lastName, setLastName] = useState()
  const [firstName, setFirstName] = useState()
  const [email, setEmail] = useState()
  const [userPassword, setUserPassword] = useState()
  const [repeatPassword, setRepeatPassword] = useState()
  const [journey, setJourney] = useState()
  const [errorCode, setErrorCode] = useState()
  const [check, setCheck] = useState()
  const [verif, setVerif] = useState(false)
  const [warn, setWarn] = useState({ mail: false, psw: false, fName: false })
  const [errorNameText, setErrorNameText] = useState('Tous les champs ne sont pas valides')
  const [borderColor, setBorderColor] = useState({ mail: false, psw: false })
  const [boxShadow, setBoxShadow] = useState()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    passwordCheck()
    setErrorCode(0)
    verification('verif')
    if (!loading && check) {
      if (error) {
        if (error.response !== undefined && errorCode !== error.response.status) {
          toggleOffFeedback()
          switch (error.response.status) {
            case 401:
              setSuccess(false); setWarn({ fName: false }); setCheck(false); setErrorNameText('Vous n\'êtes pas identifié, veuillez vous déconnecter puis vous reconnecter'); setErrorCode(error.response.status)
              break
            case 409:
              setSuccess(false); setWarn({ fName: true }); setCheck(false); setErrorNameText('L\'élève existe déja'); setErrorCode(error.response.status)
              break
            default:
              setSuccess(false); setWarn({ fName: true }); setCheck(false); setErrorNameText('Erreur système'); setErrorCode(error.response.status)
              break
          }
        }
      } else {
        toggleOffFeedback()
        verification()
      }
    }
    // eslint-disable-next-line
  }, [userPassword, repeatPassword, firstName, lastName, journey])

  const toggleOffFeedback = () => {
    setWarn({ mail: false, psw: false, fName: false })
  }

  const verification = (val) => {
    if (lastName && firstName && email && userPassword && repeatPassword && val && emailCheck() && journey) {
      if (userPassword !== repeatPassword) { setVerif(false) } else { setVerif(true) }
    } else {
      setVerif(false)
    }
  }

  const emailCheck = () => {
    if (!email) {
      setBorderColor({ mail: '#B3ABBC' })
      setWarn({ mail: true })
      return true
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null) {
      setBorderColor({ mail: '#FF0000' })
      setBoxShadow('0px 0px 3px 3px #FFFFFF')
      setWarn({ mail: true })
      return false
    }
    setWarn({ mail: false })
    setBorderColor({ mail: '#B3ABBC' })
    return true
  }

  const passwordCheck = () => {
    if (!repeatPassword) {
      setBorderColor({ psw: '#B3ABBC' })
      setWarn({ psw: false })
      return true
    }
    if (repeatPassword !== userPassword) {
      setBorderColor({ psw: '#FF0000' })
      setBoxShadow('0px 0px 3px 3px #FFFFFF')
      setWarn({ psw: true })
      return false
    } else {
      setBorderColor({ psw: '#B3ABBC' })
      setWarn({ psw: false })
      return true
    }
  }

  const clickHandlerAccount = () => {
    if (!verif) {
      return
    }
    setErrorCode(0)
    if (!firstName && !email && !userPassword) { setErrorCode(400) } else {
      dispatch(getToken({
        email: email,
        password: userPassword,
        foreignKey: email,
        firstname: firstName,
        lastname: lastName,
        access: 'premium',
        journey: journey,
        token: agencyToken
      }))
      setTimeout(() => { setSuccess(false) }, 2000)
      setCheck(false); setSuccess(true); setWarn({ fName: false }); setFirstName(''); setLastName(''); setEmail(''); setUserPassword(''); setRepeatPassword('')
      setCheck(true)
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickHandlerAccount()
    }
  }

  const renderTitle = () => {
    return (
      <MainTitle text='Créer un élève' icon={AddStudent} />
    )
  }

  const handleChangeAgency = (e) => {
    setJourney(e.target.value)
  }

  const displayAgencySelector = () => {
    if (journeys) {
      const journeysArr = journeys
      journeysArr.splice(0, 0, [1])
      return (
        <Selector style={{ width: '100%' }} onChange={(e) => { handleChangeAgency(e) }} value={journey}>
          {journeysArr.map((j, i) => {
            if (i === 0) {
              return <Opt disabled selected value=''>Choisir un parcours ...</Opt>
            } else {
              return <Opt key={i} value={j.title}>{j.title}</Opt>
            }
          })}
        </Selector>
      )
    }
  }

  const renderformContainer = () => {
    return (
      <FormContainer>
        <BoxContainer>
          <BoxTitle>Nom</BoxTitle>
          <Input
            value={lastName}
            style={{ borderColor: errorCode === 409 ? 'red' : '#B3ABBC' }}
            onChange={(e) => { setLastName(e.target.value) }}
          // onFocus={() => {showDelete('delLName', true)}}
          // onBlur={() => {setTimeout(()=>{showDelete('delLName', false)}, 100)}}
            onKeyPress={() => onKeyPress}
          />
          {errorCode === 409
            ? <DeleteInput style={{ bottom: '33%', right: '3%', height: '1rem' }} src={UiDanger} />
            : ''}
          {/* {delLName && lastName
          ? <DeleteInput style={{bottom: '37%', right: '3%'}} src={UiCross} onClick={() => emptyInput('lastName')}/>
          : ''} */}
          <FeedBack style={{ opacity: warn.fName ? '1' : '0' }}>{errorNameText}</FeedBack>
        </BoxContainer>
        <BoxContainer>
          <BoxTitle>Prénom</BoxTitle>
          <Input
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
            // onFocus={() => {showDelete('delFName', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('delFName', false)}, 100)}}
            onKeyPress={() => onKeyPress}
          />
          {errorCode === 409
            ? <DeleteInput style={{ bottom: '33%', right: '3%', height: '1rem' }} src={UiDanger} />
            : ''}
          {/* {delFName && firstName
          ? <DeleteInput style={{bottom: '37%', right: '3%'}} src={UiCross} onClick={() => emptyInput('firstName')}/>
          : ''} */}
          <FeedBack style={{ opacity: errorCode === 409 ? '0' : '0' }}>Existe</FeedBack>
        </BoxContainer>
        <BoxContainer>
          <BoxTitle>Email</BoxTitle>
          <Input
            value={email}
            style={{ borderColor: borderColor.mail, boxShadow: boxShadow }}
            onChange={(e) => { setEmail(e.target.value); emailCheck() }}
            // onFocus={() => {showDelete('delMail', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('delMail', false)}, 100)}}
            onKeyPress={() => onKeyPress}
          />
          {(warn.mail || errorCode === 409) && email
            ? <DeleteInput style={{ bottom: '33%', right: '2.5%', height: '1rem', zIndex: 20 }} src={UiDanger} />
            : ''}
          {/* {delMail && email
          ? <DeleteInput style={{bottom: '37%', right: '3%'}} src={UiCross} onClick={() => {emptyInput('email'); setState({borderColorMail:'#B3ABBC', warnMail: false})}}/>
          : ''} */}
          <FeedBack style={{ opacity: warn.mail ? '1' : '0' }}>Email non valide</FeedBack>
        </BoxContainer>
        <BoxContainer>
          <BoxTitle>Parcours</BoxTitle>
          <SelectorContainer>
            <Arrow src={ArrowDown} />
            {displayAgencySelector()}
          </SelectorContainer>
        </BoxContainer>
        <BoxContainer>
          <BoxTitle>Mot de passe</BoxTitle>
          <Input
            value={userPassword}
            type='text'
            style={{ borderColor: borderColor.psw, boxShadow: boxShadow }}
            onChange={(e) => { setUserPassword(e.target.value) }}
              // onFocus={() => {showDelete('delPsw', true)}}
              // onBlur={() => {setTimeout(()=>{showDelete('delPsw', false)}, 100)}}
            onKeyPress={() => onKeyPress}
          />
          {/* {delPsw
            ? <DeleteInput style={{ bottom: '37%', right: '3%' }} src={UiCross} onClick={() => emptyInput('userPassword')} />
            : ''} */}
          {warn.psw
            ? <DeleteInput style={{ bottom: '33%', right: '2.5%', height: '1rem' }} src={UiDanger} />
            : ''}
        </BoxContainer>
        <BoxContainer>
          <BoxTitle>Répéter le mot de passe</BoxTitle>
          <Input
            value={repeatPassword}
            type='text'
            style={{ borderColor: borderColor.psw, boxShadow: boxShadow }}
            onChange={(e) => { setRepeatPassword(e.target.value); passwordCheck() }}
            // onFocus={() => {showDelete('delRep', true); passwordCheck()}}
            // onBlur={() => {setTimeout(()=>{showDelete('delRep', false)}, 100)}}
            onKeyPress={() => onKeyPress}
          />
          {warn.psw
            ? <DeleteInput style={{ bottom: '33%', right: '2.5%', height: '1rem' }} src={UiDanger} />
            : ''}
          {/* {delRep && repeatPassword
          ? <DeleteInput style={{bottom: '37%', right: '3%'}} src={UiCross} onClick={() => {emptyInput('repeatPassword'); setState({borderColorPwd:'#B3ABBC', warnPsw: false})}}/>
          : ''} */}
          <FeedBack style={{ opacity: warn.psw ? '1' : '0' }}>Les mots de passes ne correspondent pas</FeedBack>
        </BoxContainer>
        <Button data-type='premium' style={{ backgroundColor: verif ? '#00C4F3' : '#EFF0F6', color: verif ? 'black' : '#868383' }} onClick={() => clickHandlerAccount()}>Créer l'élève</Button>
      </FormContainer>
    )
  }

  const renderResults = () => {
    return (
      <MainLayout>
        {
              success
                ? <Success>
                  <ImgSuccess src={SuccessIcon} />
                  <TextSuccess>L'élève à bien été créé !</TextSuccess>
                </Success>
                : <div />
            }
        {renderTitle()}
        <Container>
          {renderformContainer()}
        </Container>
      </MainLayout>
    )
  }
  return (
    <Bg>
      <SideBar />
      {renderResults()}
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'PlayerCreate', saga: getTokenSaga })
const arg = compose(withSaga, withConnect)(PlayerCreate)

export default arg
