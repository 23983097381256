/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_SIMU_PLAYERS } from '../App/constants'
import { getSimuPlayersSuccess, getSimuPlayersError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * getSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  const urlEval = `${serverPath}/api/playerTGDs/${id}/agencySaveTgdEvaluation?isAdmin=${isAdmin}&access_token=${token}`
  const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  let players = []
  let evaluations
  let remc

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    evaluations = yield call(request, urlEval, { method: 'GET' })
    remc = yield call(request, urlREMC, { method: 'GET' })

    players.forEach(pl => {
      const tmpEval = evaluations.filter(ev => { return ev.id === pl.id })
      const tmpProg = remc.filter(pr => { return pr.id === pl.id })

      pl.eval = tmpEval[0] ? tmpEval[0].output : {}
      pl.prog = tmpProg[0] ? tmpProg[0].save : {}
    })
    yield put(getSimuPlayersSuccess(players))
  } catch (err) {
    console.log(err)
    yield put(getSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getSimuPlayersSaga () {
  yield takeLatest(GET_SIMU_PLAYERS, getSimuPlayers)
}

// players.map(x => {
//   UrlsProg.push(`${serverPath}/api/playerTGDs/${x.id}/saveREMC?access_token=${token}`)
// })

// yield * UrlsProg.map(function * (UrlProg, i = 0) {
//   players[i].prog = yield call(request, UrlProg, { method: 'GET' })
// })

// players.map(x => {
//   UrlsEval.push(`${serverPath}/api/playerTGDs/${x.id}/SaveTgdEvaluation?access_token=${token}`)
// })

// yield * UrlsEval.map(function * (UrlEval, i = 0) {
//    players[i].eval = yield call(request, UrlEval, { method: 'GET' })
//  })
