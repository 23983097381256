const strize = require('../strize')

module.exports = {
  title: 'VINCI',
  imgs: {
    root: 'imagesVinci'
  },
  home: '/simuplayers',
  features: {
    elearning: false,
    simu: true,
    eval: false
  },
  components: {
  },
  bucket: {
    player: {
      bucket: strize('elearningmarqueblanche'),
      region: strize('eu-west-3'),
      key: strize('AKIAJ65573RMVQT2DJHQ'),
      secret: strize('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')
    },
    editor: {
      bucket: strize(''),
      region: strize(''),
      key: strize(''),
      secret: strize('')
    }
  },
  colors: {
    main: '#000000',
    bgSideBar: '#024179',
    ActionMail: '#dadada'
  },
  layouts: {
    login: {
      bottomLogo: 'LOGO_ASF.svg'
    },
    sidebar: {
      imgLogo: 'LOGO_ASF.svg'
    },
    agencyCreate: {
      logo: false
    }
  }
}
