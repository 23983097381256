import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import Newsletter from '../../images/UiActublack.svg'
const Bg = styled.div`
    min-height: 100vh;
    height: 100vh;
    width: 100%;
`
const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 85%;
  background-color: white;
  justify-content:center;
  align-items:center;
`
const MainContainer = styled.div`
display:block;
flex-direct:column;
margin-top:5rem;
`

const Bloc1 = styled.div`
    width:27.8rem;
    height:19.25rem;
    border-radius:10px;
    background:#EDF0F7;
`
const Bloc2 = styled.div`
    width:27.8rem;
    height:19.25rem;
    border-radius:10px;
    margin-top:1.5rem;
    background:#EDF0F7;
`
const Date = styled.div`
    width:6rem;
    height:2rem;
    margin-left:2rem;
    padding-top:2rem;

`
const Content = styled.div`
    width:23.68rem;
    height:7.93rem;
    font-size:1.1rem;
    font-style:normal;
    font-family:Poppins;
    font-weight:400;
    color: #4F4F4F;
    text-align:left;
    margin:2rem;
    margin-top:0.5rem;
`
const Title = styled.div`
    width:22.4rem;
    height:3.3rem;
    font-style:normal;
    font-size:1.25rem;
    font-family:Poppins;
    font-weight:700;
    margin-left:2rem;
    line-height:5rem;
`
const Button = styled.div`
    width:10.9rem;
    height:3rem;
    font-style:normal;
    font-family:Poppins;
    font-size:1rem;
    font-weight:normal;
    box-shadow:0px 2px 7px rgba(0,0,0,0.14);
    background-color:#61D7F3;
    border-radius:10px;
    text-align:center;
    margin-left:2rem;
    margin-top:-1rem;
    cursor:pointer;
    line-height:3rem;
`

const News = () => {
  return (
    <Bg>
      <SideBar />
      <MainLayout>
        <MainTitle text='Actualités' icon={Newsletter} />

        <MainContainer>
          <Bloc1>
            <Date>12-10-2020</Date>
            <Title>Nouvelle version 1.02.1 du SIMUTGD</Title>
            <Content>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </Content>
            <Button>Téléchargements </Button>
          </Bloc1>
          <Bloc2>
            <Date>05-05-2020</Date>
            <Title>Le confinement malin !</Title>
            <Content>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            </Content>
          </Bloc2>
        </MainContainer>

      </MainLayout>
    </Bg>
  )
}

export default News
