
import { call, put, takeLatest } from 'redux-saga/effects'
import { REFRESH_SIMU_PLAYERS } from '../App/constants'
import { refreshSimuPlayersError, refreshSimuPlayersSuccess } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

export function * refreshSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  let remc
  let players = []

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    remc = yield call(request, urlREMC, { method: 'GET' })

    players.forEach(pl => {
      const tmpProg = remc.filter(pr => { return pr.id === pl.id })
      pl.prog = tmpProg[0] ? tmpProg[0].save : {}
    })

    yield put(refreshSimuPlayersSuccess(players))
  } catch (err) {
    yield put(refreshSimuPlayersError(err))
  }
}

/**
   * Root saga manages watcher lifecycle
   */
export function * refreshSimuPlayersSaga () {
  yield takeLatest(REFRESH_SIMU_PLAYERS, refreshSimuPlayers)
}

/**
 * Github repos request/response handler
 */ // let url = `${serverPath}/api/players/gettoken?foreignKey=${name}&firstname=${name}&password=${userPassword}&expirationDate=2020-01-01&access_token=${serverToken}&access=${access || 'premium'}`
