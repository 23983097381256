import React, { useState } from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../../containers/SideBar/index'
import Button2 from '../../components/Button/Button2'
import ButtonArrowRight from '../../components/Button_ArrowRight'
import ArrowRight from '../../images/ArrowRight.svg'
import TitleBarem from '../../components/BaremEvalTItle'
const Bg = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StatsContainer = styled.div`
  height: 27rem;
  width: 60rem;
  margin-left:10rem;
  border-radius: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  font-family: Saira;
  font-size: 1.4rem;
  display: flex;
  position:fixed;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  align-items: center;
  margin-top:10rem;
`

const MainText = styled.div`
  width:22.18rem;
  height:6.25rem;
  position:fixed;
  left:1434px;
  top:600px;
  font-family: Poppins;
  color:#4F4F4F;
  font-weight:400;
  font-style: normal;
  font-size:1.1rem;
  line-height:140%;
  display:flex;
  align-items:center;
  text-align:left;
`
const StatsRow = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight:700;
  margin-top:7.75rem;
  margin-left:8.8rem;
  display:flex;
  height:10rem;
`
const StatsPoints = styled.div`
font-family: Catamaran;
font-weight:600;
width: 65%;
display: inline;
display: flex;
flex-direction: row;
align-items: center;
text-align:center;
color:#000000;
`

const Input = styled.input`
  width: 5.5rem;
  height:2.37rem;
  font-family: Poppins;
  font-style:normal;
  font-size: 1rem;
  line-height:140%;
  padding-left:1rem;
  border-radius:0.5rem;
  border:1px solid #B3ABBC;
  margin-top:1rem;
  background-color:white;
  z-index:2;
`
const Span1 = styled.span`
  width: 8rem;
  font-size: 1.1rem;
  align-items:center;
  padding-top:1rem;
  font-family:Poppins;
  font-weight:500;
  font-style:normal;
`
const SpanB = styled.span`
  width: 9.5rem;
  border-radius:10px;
  height:40rem;
  margin-top:6rem;
  position:absolute;
  margin-left:0;
  font-family:Poppins;
  padding-bottom:1rem;
  z-index:-1;
  text-align:center;
  font-weight:500;
  line-height:5rem;
  font-size: 1.25rem;
  background-color: #CCEAFF;
`
const SpanBEA = styled.span`
  width: 9.5rem;
  border-radius:10px;
  height:40rem;
  padding-bottom:1rem;
  margin-top:6rem;
  position:absolute;
  margin-left:10.1rem;
  z-index:-1;
  text-align:center;
  font-family:Poppins;
  font-weight:500;
  font-style:normal;
  line-height:5rem;
  font-size: 1.25rem;
  background-color: #B3DFFF;
`
const SpanAAC = styled.span`
  width: 9.5rem;
  height:40rem;
  margin-top:6rem;
  padding-bottom:1rem;
  z-index:-1;
  background-color: #99D5FF;
  border-radius:10px;
  text-align:center;
  line-height:5rem;
  font-size: 1.25rem;
  font-family:Poppins;
  font-weight:500;
  text-align:center;
  margin-left:20.2rem;
`

const BackStats = styled.div`
  width:9.5rem;
  margin-left:1.2rem;
`
const BackStats1 = styled.div`
  width:9.5rem;
  margin-left:1rem;
`
const BackStats2 = styled.div`
  width:9.5rem;
  margin-left:1rem;
`
const LateralBar = styled.div`
  position:absolute;
  width:9.6rem;
  left:80rem;
  top:42.5rem;
  border: 1px solid #BDBDBD;
  transform:rotate(90deg);
`
const defaultScale = {
  1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}

const SetScaleEvalLayout = (props) => {
  const [scale, setScale] = useState(props.scale != null && props.scale.toJS()) || Object.assign({}, defaultScale)

  const setCell = (type, index, value) => {
    setScale(p => {
      const scale = Object.assign({}, p.scale)
      scale[type][index] = value
      return { scale }
    })
  }

  const renderCell = (type, index) => {
    return (
      <Input
        value={scale[type][index]} onChange={(e) => {
          let parsed = parseInt(e.target.value)
          if (isNaN(parsed)) { parsed = 0 }
          setCell(type, index, parsed)
        }}
      />
    )
  }

  const renderRow = (index) => {
    return (
      <StatsPoints>
        <Span1>
          De {index * 10 + 1} à {(index + 1) * 10}
          <ButtonArrowRight icon={ArrowRight} />
        </Span1>
        <BackStats>
          {renderCell('1', index)}
        </BackStats>
        <BackStats1>
          {renderCell('2', index)}
        </BackStats1>
        <BackStats2>
          {renderCell('3', index)}
        </BackStats2>
      </StatsPoints>
    )
  }

  const renderHeaderRow = (index) => {
    return (
      <StatsRow>
        <SpanB> Permis B </SpanB>
        <SpanBEA> Permis BEA </SpanBEA>
        <SpanAAC> Permis AAC </SpanAAC>
      </StatsRow>
    )
  }

  const renderScale = () => {
    const indexes = new Array(10).fill('0').map((v, i) => i)
    return (

      <MainLayout>
        <TitleBarem />
        <StatsContainer>
          {renderHeaderRow()}
          {indexes.map(v => renderRow(v))}
          <Button2 text='Confirmer mes barèmes' onClick={() => props.setScale(scale)} />
        </StatsContainer>
        <LateralBar />
        <MainText>
          Précisez ci-contre le nombre de séquences prévu selon les scores.
          Ex : pour le «De 81 à 90», écrire «22»
          pour 22 heures ou 22 séquences.
        </MainText>
      </MainLayout>
    )
  }

  if (props.player == null) {
    return (
      <Bg>
        <SideBar />
        {renderScale()}
      </Bg>
    )
  }
}

export default SetScaleEvalLayout
