import { withRouter, useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import ProgressBar from '../../components/ProgressBar'
import Circle from '../../components/StatsCircle'
import arrow from '../../images/arrowML.svg'
import ArrowDown from '../../images/ArrowDownSelector.svg'
import { refreshSimuPlayersSaga } from '../RefreshPlayers/saga'
import statsLogo from '../../images/Stats.svg'
import { SelectorContainer, Selector, Opt, StatsBox, Percent, BoxTitle, Bg, Stats, StatsRow, StatsRowKM, SkillTitle, SkillsContainer, SkillsContainer2, MainLayout, MainContainer, ArrowStudent, Info, InfoContainer, Parcours, StatsTime, StatsTitle, Score } from './styledComponents'

const StatsSimu = () => {
  const location = useLocation()
  const history = useHistory()

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const accessToken = useSelector((state) => state.toJS().global.agencytoken)

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const player = simuPlayers ? simuPlayers.find(player => player.id.toString() === id) : ''

  const [colors, setColors] = useState([' ', ' ', ' '])
  const [borderBottom, setBorderBottom] = useState([' ', ' ', ' '])
  const [circleSize, setCircleSize] = useState(80)
  const [stats, setStats] = useState()

  useEffect(() => {
    window.addEventListener('resize', ResizeCircle)
    // if (!simuPlayers) { dispatch(refreshSimuPlayers(token, agencyid, isAdmin)) }
    if (player) { refreshStatsRemc() }
    if (simuPlayers) {
      const id = location.pathname.split('/')[3]
      const player = simuPlayers.find(player => player.id.toString() === id)
      if (player && !stats) {
        refreshStatsRemc()
      }
    }
    refreshStatsRemc()
    // eslint-disable-next-line
  }, [player.id])

  const ResizeCircle = () => {
    setCircleSize(window.innerWidth / 17)
  }

  const refreshStatsRemc = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/playerTGDs/${player.id}/getstatsremc?access_token=${accessToken}`)
    const data = await response.json()
    setStats(data)
  }

  const getTime = (time) => {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt(time / 60 % 60)

      return h + 'h ' + m + 'm'
    }
    return 'Never played'
  }

  const update = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/playerTGDs/${id}/getstatsremc?access_token=${accessToken}`)
    const data = await response.json()
    setStats(data)
  }

  const handleChange = (e) => {
    history.push(`/simuplayers/stats/${e.target.value}`)
    update(e.target.value)
  }

  const displaySelector = () => {
    if (simuPlayers) {
      return (
        <Selector style={{ marginTop: '4rem', outline: 'none' }} onChange={(e) => { handleChange(e) }} value={id}> {/* value={[player.get('id'), player.get('firstname'), player.get('lastname'), player.get('email'), player.get('agencyId')] */}
          {simuPlayers.map((pl, i = 0) => {
            if (pl.foreignKey === 'fkeditor') {
              return null
            }
            return <Opt key={i} value={pl.id}>{pl.firstname + ' ' + pl.lastname}</Opt>
          })}
        </Selector>
      )
    }
  }

  // switch color in the infoContainer to know where we are
  const onClickColor = (e) => {
    setColors([
      e === 0 ? '#166DD8' : 'black',
      e === 1 ? '#166DD8' : 'black',
      e === 2 ? '#166DD8' : 'black'
    ])
    setBorderBottom([
      e === 0 ? '3px solid #166DD8' : 'black',
      e === 1 ? '3px solid #166DD8' : 'black',
      e === 2 ? '3px solid #166DD8' : 'black'
    ])
  }

  const getRatios = () => {
    console.log(stats)
    if (stats != null) {
      const ecoStat = (stats.ecoAccelerator_global + stats.ecoStableSpeed_global + stats.ecoBrakes_global) / 3
      return (
        // here we are going in the state to find the stats and then in the stats we found which stats we need in the json
        <StatsBox>
          <BoxTitle>
            Position
            <Circle size={circleSize} ratio={(stats.position_global)} />
            <Percent>{Math.round((stats.position_global) * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Allure
            <Circle size={circleSize} ratio={(stats.allure_global)} />
            <Percent>{Math.round((stats.allure_global) * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Rétroviseurs
            <Circle size={circleSize} ratio={(stats.retroviseur_global)} />
            <Percent>{Math.round((stats.retroviseur_global) * 100) || 0}</Percent>
          </BoxTitle>
          {/* <StatsBox2> */}
          <BoxTitle>
            Regard
            <Circle size={circleSize} ratio={(stats.regard_global)} />
            <Percent>{Math.round((stats.regard_global) * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Communication
            <Circle size={circleSize} ratio={(stats.communication_global)} />
            <Percent>{Math.round((stats.communication_global) * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            EcoConduite
            <Circle size={circleSize} ratio={ecoStat} />
            <Percent>{Math.round((ecoStat) * 100) || 0}</Percent>
          </BoxTitle>
          {/* </StatsBox2> */}
        </StatsBox>
      )
    }
  }

  if (player) {
    return (
      <Bg>
        <SideBar />
        <MainLayout>
          <MainTitle text='Statistiques' icon={statsLogo} />
          <MainContainer>
            <SelectorContainer>
              <ArrowStudent src={ArrowDown} />
              {displaySelector()}
            </SelectorContainer>
            <InfoContainer>
              <Info
                to={`/playersimu/edit/${id}`}
                style={{
                  color: colors[0],
                  borderBottom: borderBottom[0]
                }}
                onClick={(e) => onClickColor(0)}
              >
                Informations
              </Info>
              <Parcours
                style={{
                  color: colors[1],
                  borderBottom: borderBottom[1]
                }}
                onClick={(e) => onClickColor(1)}
              >
                Parcours
              </Parcours>
              <Stats
                style={{
                  color: colors[2],
                  borderBottom: borderBottom[2]
                }}
                onClick={(e) => onClickColor(2)}
              >
                Statistiques
              </Stats>
            </InfoContainer>
            <StatsRow>
              <StatsTitle src={arrow}>Trajets validés</StatsTitle>
              <StatsTime>
                {player.prog.Completion ? `${player.prog.Completion.Count || 0} / ${player.prog.Completion.CountTotal || 0}` : '0 / 55'}
              </StatsTime>
            </StatsRow>
            <SkillsContainer>
              <SkillTitle>
                <Score>{player.prog.Completion ? player.prog.Completion.Counts[0] : 0}/13</Score>
                Compétence 1
                <ProgressBar percentage={player.prog.Completion ? player.prog.Completion.Percents[0] : 0} />
              </SkillTitle>
              <SkillTitle>
                <Score>{player.prog.Completion ? player.prog.Completion.Counts[1] || 0 : 0}/16</Score>
                Compétence 2
                <ProgressBar percentage={player.prog.Completion ? (player.prog.Completion.Percents[1] ? player.prog.Completion.Percents[1] : 0) : 0} />
              </SkillTitle>
              <SkillTitle>
                <Score>{player.prog.Completion ? (player.prog.Completion.Counts[2] || 0) : 0}/21</Score>
                Compétence 3
                <ProgressBar percentage={player.prog.Completion ? (player.prog.Completion.Percents[2] ? player.prog.Completion.Percents[2] : 0) : 0} />
              </SkillTitle>
              <SkillTitle>
                <Score>{player.prog.Completion ? (player.prog.Completion.Counts[3] || 0) : 0}/5</Score>
                Compétence 4
                <ProgressBar percentage={player.prog.Completion ? (player.prog.Completion.Percents[3] ? player.prog.Completion.Percents[3] : 0) : 0} />
              </SkillTitle>
            </SkillsContainer>
            <StatsRow>
              <StatsTitle>Temps de conduite</StatsTitle>
              <StatsTime>
                {player.prog.PlayTime ? getTime(player.prog.PlayTime) : '0h 0m'}
              </StatsTime>
            </StatsRow>

            <StatsRowKM>
              <StatsTitle>Kilomètres parcourus</StatsTitle>
              <StatsTime>
                {player.prog.DistancePlay ? `${parseInt(player.prog.DistancePlay / 1000)} km ${parseInt(player.prog.DistancePlay) % 1000}` : '0km'}
              </StatsTime>
            </StatsRowKM>
            <SkillsContainer2>
              {getRatios()}
            </SkillsContainer2>
          </MainContainer>
        </MainLayout>
      </Bg>
    )
  } else {
    return (
      <div>
        LOADING
      </div>

    )
  }
}

const withSaga = injectSaga({
  key: 'refreshSimuPlayers',
  saga: refreshSimuPlayersSaga
})

const withConnect = connect(null, null)
const arg = compose(withSaga, withConnect)(StatsSimu)

// let arg = compose(withConnect)(StatsSimu)

export default withRouter(arg)
