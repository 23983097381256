import React, { useState } from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import Circle from '../../images/ellipse-yellow.png'
import TopBar from '../TopBar/index'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import { changePasswordData } from './saga'
import { changePassword } from '../App/actions'

const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

const Button = styled.button`
  margin: auto;
  margin-top: 2rem;
  width: 11.5rem;
  height: 1.95rem;
  font-family: Saira;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  background-color: #0072bc;
`

const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
  margin-top: 3rem;
`

const ErrorText = styled.div`
  font-family: Catamaran;
  font-size: 1rem;
  font-color: red;
  color: red;
  font-weight: 600;
  text-align: center;
  margin: auto;
  margin-bot: 1rem;
`

const Input = styled.input`
  display: block;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 2px #dadada;
  width: 15rem;
  height: 1.95rem;
  margin: auto;
  margin-bottom: 0.6rem;
  padding-left: 1.65rem;
  background: url(${Circle}) no-repeat scroll 0.55rem 0.55rem white;
`

const SuccessText = styled.div`
  font-family: Catamaran;
  font-size: 1rem;
  font-color: green;
  color: green;
  font-weight: 600;
  text-align: center;
  margin: auto;
`

const ChangePassword = () => {
  const dispatch = useDispatch()

  const players = useSelector((state) => state.global.simuPlayers)
  const error = useSelector((state) => state.global.error)
  const token = useSelector((state) => state.global.agencytoken)
  const pathid = useSelector((state) => state.route.location.pathname)

  const [password, setPassword] = useState()
  const [passwordRep, setPasswordRep] = useState()
  const [errorCode, setErrorCode] = useState()

  const handleClick = () => {
    setErrorCode(0)
    if (password === '') { setErrorCode(403) } else if (password !== passwordRep) { setErrorCode(401) } else {
      const id = pathid.substr(10) // FIXME!!!!
      dispatch(changePassword({ id, token: token, password: password }))
      if (error === false) { setErrorCode(200) } else { setErrorCode(404) }
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  const id = pathid.substr(10)
  const player = players ? players.find(player => player.get('id').toString() === id.toString()) : null
  return (

    <Bg>
      <SideBar />
      <TopBar
        title={'Changer le mot de passe d\'un élève'}
        firstname={player && player.get('firstname')}
        lastname={player && player.get('lastname')}
        email={player && player.get('email')}
      />
      <MainLayout>
        {errorCode === 401 ? <ErrorText>Les mots de passe ne sont pas identiques.</ErrorText> : null}
        {errorCode === 403 ? <ErrorText>Le mot de passe ne peut être vide.</ErrorText> : null}
        {errorCode === 404 ? <ErrorText>An unknown error occured.</ErrorText> : null}
        {errorCode === 200 ? <SuccessText>Mot de passe changé avec succès.</SuccessText> : null}
        <Input placeholder='mot de passe' value={password} type='password' onChange={(e) => setPassword(e.target.value)} />
        <Input onKeyPress={() => onKeyPress} placeholder='répéter mot de passe' value={passwordRep} type='password' onChange={(e) => setPasswordRep(e.target.value)} />
        <Button data-type='premium' onClick={() => handleClick()}>
          CONFIRMER
        </Button>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'ChangePassword', saga: changePasswordData })
const arg = compose(withSaga, withConnect)(ChangePassword)

export default arg
