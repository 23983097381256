import React from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { setScaleEvalSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { setScaleEval } from '../App/actions'
import ScaleEvalLayout from '../../components/ScaleEvalLayout'

const ScaleEval = () => {
  const dispatch = useDispatch()
  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyId = useSelector((state) => state.toJS().global.agencyid)
  const evalScale = useSelector((state) => state.toJS().global.evalScale)

  return <ScaleEvalLayout scale={evalScale} setScale={scale => dispatch(setScaleEval(scale, agencyId, agencyToken))} />
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'setScaleEvalSaga', saga: setScaleEvalSaga })
const arg = compose(withSaga, withConnect)(ScaleEval)

export default arg
