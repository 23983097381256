/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getSimuPlayersSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getSimuPlayers } from '../App/actions'

const SimuPlayersLoader = () => {
  const dispatch = useDispatch()

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const id = useSelector((state) => state.toJS().global.agencyid)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  useEffect(() => {
    if (simuPlayers == null && agencytoken != null) {
      dispatch(getSimuPlayers(agencytoken, id, isAdmin))
    }
    // eslint-disable-next-line
  }, [simuPlayers, agencytoken])

  return <div />
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'SimuPlayersLoader', saga: getSimuPlayersSaga })
const arg = compose(withSaga, withConnect)(SimuPlayersLoader)

export default arg
