/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getTokenSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getToken, getAgencyToken } from '../App/actions'
import '../../fonts/fonts.css'
import config from '../../config/configurator'

const configImgs = config.imgs.root
const Logo = React.lazy(() => import(`${configImgs}/Logo.png`))

// background-image: url(${require(`../../${configImgs}/Logo.jpg`)});

const Bg = styled.div`
  text-align:center;
  height: 100%;
  width: 100%;
  color: white;
  align-items: center;
  min-height: 100vh;
`

const LogoImg = styled.img`
  height: 20%;
  width: 28%;
  margin-top: 10%;
  cursor: pointer;
`

const Container = styled.div`
  text-align: center;
  items-align: center;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  color: black;
  border-radius: 7px;
  background-color: #ffffff;
  opacity: 0.5;
  border: solid 1px #ffffff;
  width: 30%;
  height: 2.25rem;
  padding-left: 0.9rem;
  margin: auto;
  padding-left: 5px;
  outline: unset;
`

const Button = styled.div`
    height: 2.25rem;
    opacity: 0.85;
    border-radius: 7px;
    width: 30%;
    background-color: #000000;
    font-family: Saira;
    font-size: 1.05rem;
    font-weight: 600;
    text-align: center;
    color: #b2b8ff;
    cursor: pointer;
    margin: auto;
    margin-top: 2rem;
    padding-top: 0.5rem;

`

const BigTitle = styled.div`
  font-family: Saira;
  font-size: 3.5rem;
  font-weight: 800;
  width: 100%;
  padding-top: 3%;
  font-color: white;
`
const TextContainer = styled.div`
  width: 30%;
  text-align: center;
  margin: auto;
  margin-top: 0.8rem;
`

const Text = styled.div`
  font-family: Catamaran;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
`

const ErrorText = styled.div`
  font-family: Catamaran;
  font-size: 1rem;
  font-color: red;
  color: red;
  font-weight: 600;
  text-align: center;
  margin: auto;
`
const serverPath = process.env.REACT_APP_SERVER_FRONT

const AdminHomePage = () => { // eslint-disable-line react/prefer-stateless-function
  const dispatch = useDispatch()

  const token = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)

  const [wrongRepeat, setWrongRepeat] = useState()
  const [errorCode, setErrorCode] = useState()
  const [userPassword, setUserPassword] = useState()
  const [agencyPasswordRep, setAgencyPasswordRep] = useState()
  const [agencyPassword, setAgencyPassword] = useState()
  const [agencyName, setAgencyName] = useState()
  const [repeatPassword, setRepeatPassword] = useState()
  const [name, setName] = useState()
  const [password, setPassword] = useState()
  const [where, setWhere] = useState()

  useEffect(() => {
    if (token) {
      window.top.location = serverPath + '#' + token
    }
    if (error !== undefined) {
      if (error.response !== undefined) {
        if (errorCode !== error.response.status) { setErrorCode(error.response.status) }
      }
    }
    // eslint-disable-next-line
  }, [error])

  const clickHandlerAccount = () => {
    setWrongRepeat(false); setErrorCode(0)
    if (userPassword !== repeatPassword) { setWrongRepeat(true) } else { dispatch(getToken(name, password, 'premium', userPassword)) }
  }

  const clickHandlerAgency = () => {
    setWrongRepeat(false); setErrorCode(0)
    if (agencyPasswordRep !== agencyPassword) { setWrongRepeat(true) } else { dispatch(getToken(name, password, 'premium', userPassword)) }
  }

  if (where === 'home') {
    return (
      <Bg>
        <LogoImg src={require(`../../${configImgs}/Logo.jpg`)} />
        <BigTitle>Administration</BigTitle>
        <Button onClick={() => { setWhere('createaccount') }}>CREER UN COMPTE</Button>
        <Button onClick={() => { setWhere('createagency') }}>AJOUTER UNE AGENCE</Button>
      </Bg>
    )
  } else if (where === 'createaccount') {
    return (
      <Bg>
        <Logo onClick={() => { setWhere('home') }} src={require(`../../${configImgs}/Logo.jpg`)} />
        <Container>
          <BigTitle>Créer un compte</BigTitle>
          {wrongRepeat
            ? <ErrorText>Les mots de passes ne correspondent pas.</ErrorText>
            : null}
          {errorCode === 401
            ? <ErrorText>Mot de passe administrateur erroné.</ErrorText>
            : null}
          {errorCode === 409
            ? <ErrorText>{name} existe déjà.</ErrorText>
            : null}
          <TextContainer><Text>nom d'utilisateur :</Text></TextContainer>
          <Input placeholder='ex: Michael' value={name} onChange={(e) => setName(e.target.value)} />
          <TextContainer><Text>mot de passe :</Text></TextContainer>
          <Input value={userPassword} type='password' onChange={(e) => setUserPassword(e.target.value)} />
          <TextContainer><Text>répéter mot de passe :</Text></TextContainer>
          <Input value={repeatPassword} type='password' onChange={(e) => setRepeatPassword(e.target.value)} />
          <TextContainer><Text>mot de passe administrateur :</Text></TextContainer>
          <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          {/* <TextContainer><Text>Agence :</Text></TextContainer>
          <Input placeholder='ex: ECF Paris 11' value={agency} onChange={(e) => setState({agency: e.target.value})} /> */}
          <Button data-type='premium' onClick={() => clickHandlerAccount()}>
            S'INSCRIRE
          </Button>
          {/* <Button onClick={() => getToken(name, password, 'demo', userPassword)}>
            LOGIN DEMO
          </Button> */}
        </Container>
      </Bg>
    )
  } else if (where === 'createagency') {
    return (
      <Bg>
        <Logo onClick={() => { setWhere('home') }} src={require(`../../${configImgs}/Logo.jpg`)} />
        <BigTitle>Créer une agence</BigTitle>
        <TextContainer><Text>nom d'agence :</Text></TextContainer>
        <Input placeholder='ex: ECF Paris 11' value={agencyName} onChange={(e) => setAgencyName(e.target.value)} />
        <TextContainer><Text>mot de passe :</Text></TextContainer>
        <Input value={agencyPassword} type='password' onChange={(e) => setAgencyPassword(e.target.value)} />
        <TextContainer><Text>répéter mot de passe :</Text></TextContainer>
        <Input value={agencyPasswordRep} type='password' onChange={(e) => setAgencyPasswordRep(e.target.value)} />
        <Button data-type='premium' onClick={() => clickHandlerAgency()}>
          AJOUTER
        </Button>
      </Bg>

    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getAgencyToken: (name, password) => {
      dispatch(getAgencyToken(name, password))
    },
    getToken: (name, password, access, userPassword) => {
      dispatch(getToken(name, password, access, userPassword))
    }
  }
}

const withConnect = connect(null, mapDispatchToProps)
const withSaga = injectSaga({ key: 'AdminHomePage', saga: getTokenSaga })
const arg = compose(withSaga, withConnect)(AdminHomePage)

export default arg
