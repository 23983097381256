import React from 'react'
import styled from 'styled-components'
import ArrowTop from '../../images/ArrowTop.svg'
import ButtonArrow from '../../components/ButtonArrow'
import config from '../../config/configurator'

const configColors = config.colors.main

const Bar = styled.div`
display: flex;
justify-content: center;
align-items: center;  
height: 3.25rem;
width: calc(100% - 18.14rem);
margin-left:1rem;
background-color: ${configColors};
@media(max-width:1024px)
  {
    width:100%; 
  }
  @media(max-width:1280px)
  {
    width:100%; 
  }
`

const TopText = styled.div`
  font-size: 1.875rem;
  line-height:1.650rem;
  font-weight: 700;
  font-style:normal;
  text-align center;
  color: white;
`

const TopBar = (props) => {
  return (
    <Bar>
      <ButtonArrow icon={ArrowTop} />
      <TopText>{props.title}</TopText>
    </Bar>
  )
}

export default TopBar
