import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'

const BoxContainer = styled.div`
display:flex;
flex-direction:column;
`
const Input = styled.input`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  height: 2.37rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  &:focus {outline:none;}
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
`

const InputBox = () => {
  return (
    <BoxContainer>
      <Input />
    </BoxContainer>
  )
}

export default InputBox
