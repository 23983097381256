/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
// import ReactDataGrid from 'react-data-grid'
// import ProgressionBar from '../ProgressionBar'
// import { Toolbar, Data } from '../../lib/react-data-grid-addons' // no more react-data-grid -> ag-grid
import './GridOverload.css'
import ModalConfirm from '../../components/ModalConfirm'
import { deleteSimuPlayerSaga, refreshSimuPlayersSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deleteSimuPlayer, refreshSimuPlayers } from '../App/actions'
import ShouldRefreshSimuPlayers from '../ShouldRefreshSimuPlayers/index'
import { Link } from 'react-router-dom'
import Icon from '../../images/boy.svg'

const Bg = styled.div`
  width: 100%;
  height: 99%;
  background-color: white;
`

const LinkDetails = styled(Link)`
  text-decoration: none;
  color: #0572bd;
  text-align: center;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
`

const Progression = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

`

const ProgressionBar = styled.div`
  margin-left: 0.4rem;
  width: 65%;
  height: 1.3rem;
  background: white; 
  border-radius: 0.3rem;
  margin-top: 0.2rem;
`

const ProgressionBarIn = styled.div`
  height: 100%;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, #1AA0FF 0%, #166DD8 95.42%);
`

const ProgressionText = styled.div`
  width: 25%;
  margin-left: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
`

const Date = styled.div`
  font-family: Poppins;
  font-weight: 300;
  text-align: center;
`

const Name = styled.div`
  font-weight: 600;
  font-family: Poppins;
  margin-left:0.2rem;
`

const NameIcon = styled.img`
  height: 1rem;
  margin-right: 0.6rem;
`
const LightCell = styled.div`
  font-family: Poppins;
  margin-left: 0.7rem;  
  font-damily: Poppins;
  font-weight: 300;
`

const TopBar = styled.div`
  display: flex;
  width: 70%;
  height: 10%;
  margin-left: 33.3%;
  margin-top: 5%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.2rem;
`

const EvalBar = styled.div`
  width: 40%;
  border-bottom: solid #53AD49 0.3rem;
`

const SimulBar = styled.div`
  margin-left: 3%;
  width: 41%;
  border-bottom: solid #297FCC 0.3rem;
`

// const selectors = Data.Selectors

// const handleFilterChange = filter => filters => {
//   const newFilters = { ...filters }
//   if (filter.filterTerm) {
//     newFilters[filter.column.key] = filter
//   } else {
//     delete newFilters[filter.column.key]
//   }
//   return newFilters
// }

// const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
//   const getRatio = v => {
//     const seqCount = v.sequenceCount || 0
//     const scoreRatio = Math.round(
//       (seqCount ? v.sequenceDoneCount / v.sequenceCount : 0) * 100
//     ) +
//       '%'

//     return scoreRatio
//   }

//   const comparer = (a, b) => {
//     if (sortColumn === 'scoreRatio') {
//       if (sortDirection === 'ASC') {
//         return getRatio(a[sortColumn]) > getRatio(b[sortColumn]) ? 1 : -1
//       } else if (sortDirection === 'DESC') {
//         return getRatio(a[sortColumn]) < getRatio(b[sortColumn]) ? 1 : -1
//       }
//     }

//     if (sortDirection === 'ASC') {
//       return a[sortColumn] > b[sortColumn] ? 1 : -1
//     } else if (sortDirection === 'DESC') {
//       return a[sortColumn] < b[sortColumn] ? 1 : -1
//     }
//   }
//   return sortDirection === 'NONE' ? initialRows : [...rows].sort(comparer)
// }

// const columnWidths = [350, 160, 135, 165, 165, 194, 270]
// const columns = []

// const columns = [
//   {
//     key: 'firstname',
//     name: 'El\xE8ves',
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 0
//   },
//   {
//     key: 'licence',
//     name: 'Permis',
//     sortable: true,
//     editable: false,
//     filterable: false,
//     width: 0
//   },
//   ...config.features.elearning
//     ? {
//         key: 'eval',
//         name: 'Eval\'',
//         sortable: true,
//         editable: false,
//         filterable: false,
//         width: 0
//       }
//     : {},
//   {
//     key: 'vol',
//     name: 'Volume',
//     sortable: true,
//     editable: false,
//     filterable: false,
//     width: 0
//   },
//   {
//     key: 'date',
//     name: 'Date',
//     sortable: true,
//     editable: false,
//     filterable: false,
//     width: 0
//   },
//   {
//     key: 'playTime',
//     name: 'Conduite',
//     sortable: true,
//     editable: false,
//     filterable: false,
//     width: 0
//   },
//   {
//     key: 'progression',
//     name: 'Progression',
//     sortable: true,
//     editable: false,
//     width: 0
//   },
//   {
//     key: 'actions',
//     name: 'Actions',
//     sortable: true,
//     editable: false,
//     width: 0,
//     formatter: PlayerCellActionsArchive
//   }
// ]

// {
// const columnAdminWidths = [100, 292, 150, 200, 150, 150, 50, 200] // isolate column widths to havee them really constant !
// const columnsAdmin = [
//   {
//     key: 'firstname',
//     name: 'nom/pr\xE9nom'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 292
//   },
//   {
//     key: 'email',
//     name: 'email'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 150
//   },
//   {
//     key: 'date',
//     name: 'stage'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 200
//   },
//   {
//     key: 'playTime',
//     name: 'temps de jeu'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 150
//   },
//   {
//     key: 'progression',
//     name: 'progression'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 150
//   },
//   // { key: 'id', name: 'ID'.toUpperCase(), sortable: true, editable: false, filterable: true, width: 300 },
//   {
//     key: 'eval',
//     name: 'evl'.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 50
//   },
//   {
//     key: 'actions',
//     name: ''.toUpperCase(),
//     sortable: true,
//     editable: false,
//     filterable: true,
//     width: 200,
//     formatter: PlayerCellActionsArchive
//   }
// ]
// }

const SimuPlayerArchive = (props) => {
  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyid = useSelector((state) => state.toJS().global.agencyid)

  const [modalName, setModalName] = useState()
  const [modalText, setModalText] = useState()
  const [showModal, setShowModal] = useState(false)
  const [sessionFilter, setSessionFilter] = useState({
    begin: '2000-01-01',
    end: '9999-01-01',
    showModal: false
  })

  const rowRatio = () => {
    return window.innerWidth / 1920
  }

  const findAgencyName = (agencyId) => {
    if (agencies == null) {
      return '???'
    }

    if (agencyId == null) {
      return '_none'
    }

    const agency = agencies.find(
      a => a.get('id').toString() === agencyId.toString()
    )

    return agency != null ? agency.get('agencyname') : '_lost'
  }

  const convertInTime = (time, access) => {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt(time / 60 % 60)
      const s = parseInt(time % 60)

      return h + 'h ' + m + 'm' + s + 's'
    }
    return access === 'full' ? 'Non démarré' : 'Non inscrit'
  }

  const buildRows = (players) => {
    if (players == null) {
      return []
    }
    return players.map(player => {
      console.log(player.get('id_permis'))
      console.log('playe', player.get('archived'))
      if (!player.get('archived')) { return null }
      return {
        firstname: <Name><NameIcon src={Icon} />{player.get('lastname') + ' ' + player.get('firstname')}</Name>,
        licence: <LightCell>{player.get('id_permis')}</LightCell>,
        eval: <LinkDetails to={{ pathname: `simuplayer/eval/${player.get('id')}`, state: { playerId: player.get('id') } }}> {player.getIn(['eval', 'totalScore'])}</LinkDetails>,
        vol: <LightCell>{0} heure{true ? 's' : ''}</LightCell>,
        date: <Date>{player.get('created_at') != null ? moment(player.get('created_at')).format('YYYY/MM/DD') : moment(player.getIn(['eval', 'endDate'])).format('YYYY/MM/DD')}</Date>,
        playTime: <LightCell>{convertInTime(player.get('prog').get('PlayTime'), player.get('access'))}</LightCell>,
        progression:
  <Progression>
    <ProgressionBar><ProgressionBarIn style={{ width: `${player.getIn(['prog', 'Completion', 'Count']) / player.getIn(['prog', 'Completion', 'CountTotal']) * 100}%` }} /></ProgressionBar>
    <ProgressionText>{player.getIn(['prog', 'Completion', 'Count'])}/{player.getIn(['prog', 'Completion', 'CountTotal'])}</ProgressionText>
  </Progression>,

        actions: {
          id: player.get('id'),
          access: player.get('access'),
          name: player.get('firstname') + ' ' + player.get('lastname'),
          confirmDelete: (id, action, firstname) => confirmDelete(id, action, firstname)
        }
      }
    }).toJS()
  }

  const confirmDelete = (id, action, firstname) => {
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  const deleteF = (id) => {
    deleteSimuPlayer(
      agencytoken,
      id,
      agencyid
    )
  }

  useEffect(() => {

    // FIXME only update if necessary to avoid infinite loops
  }, [])

  const getCellActions = (column, row) => {
  }

  const getRows = (rows, filters) => {
    // const filtered = selectors.getRows({ rows, filters }) // FIXME: No more react-data-grid -> AG GRID
    // return filtered.filter(row => {
    //   return moment(
    //     row.date || moment()
    //   ).isBetween(sessionFilter.begin, sessionFilter.end)
    // })
  }

  const setSession = (type) => {
    switch (type) {
      case 'now':
        setSessionFilter({
          type: 'now',
          begin: moment().subtract(7, 'd'),
          end: moment().add(7, 'd')
        })
        break
      case 'future':
        setSessionFilter({
          type: 'future',
          begin: moment().add(7, 'd'),
          end: '9999-01-01'
        })
        break
      case 'archive':
        setSessionFilter({
          type: 'archive',
          begin: '2000-01-01',
          end: moment().subtract(7, 'd')
        })
        break
      default:
    }
  }

  const dirtyStyle = (type) => {
    // FIXME !!!!!!!!!!!!
    if (sessionFilter.type === type) {
      return { backgroundColor: '#febc11', borderColor: '#febc11' }
    }
    return {}
  }

  const renderPlayers = () => {
    // const filteredRows = rows // getRows(rows, filters)
    // const cols = (isAdmin ? columnsAdmin : columns).map((col, i) => {
    //   col.width = rowRatio * columnWidths[i] // rowRatio * (isAdmin ? columnAdminWidths[i] : columnWidths[i])
    //   return col
    // })

    return (
      <Bg>
        <ShouldRefreshSimuPlayers {...props} />
        <ModalConfirm
          show={showModal}
          text={modalText}
          name={modalName}
          confirm={() => { deleteF(agencyid); setShowModal(false) }}
          cancel={() => setShowModal(false)}
        />
        <TopBar>
          <EvalBar>Evaluation</EvalBar>
          <SimulBar>Simulation</SimulBar>
        </TopBar>
        <Container className='grid'>
          {/* <ReactDataGrid
            columns={cols}
            rowHeight={60}
            headerRowHeight={60}
            rowGetter={i => filteredRows[i]}
            rowsCount={(filteredRows && filteredRows.length) || 0}
            getCellActions={(column, row) => getCellActions(column, row)}
            onGridSort={
              (sortColumn, sortDirection) =>
                setState({
                  rows: sortRows(rows, sortColumn, sortDirection)(
                    rows
                  )
                })
            }
            toolbar={<Toolbar filterRowsButtonText='RECHERCHER' enableFilter />}
            onAddFilter={
              filter =>
                setState({
                  filters: handleFilterChange(filter)(filters)
                })
            }
            onClearFilters={() => setState({ filters: {} })}
            minHeight={((filteredRows && filteredRows.length) || 0) * (60 + 20)}
            minWidth={1450 * rowRatio}
          /> */}
        </Container>
      </Bg>
    )
  }

  if (simuPlayers == null) {
    return <div>LOADING</div>
  }

  return (
    <div>
      {renderPlayers()}
    </div>
  )
}

const mapStateToProps = state => ({

})

function mapDispatchToProps (dispatch) {
  return {
    deleteSimuPlayer: (token, id, agencyid) => {
      dispatch(deleteSimuPlayer(token, id, agencyid))
    },
    refreshSimuPlayers: (token, agencyid, isAdmin) => {
      dispatch(refreshSimuPlayers(token, agencyid, isAdmin))
    }
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSaga = injectSaga({
  key: 'deleteSimuPlayer',
  saga: deleteSimuPlayerSaga
})
const withRefreshSaga = injectSaga({
  key: 'refreshSimuPlayers',
  saga: refreshSimuPlayersSaga
})
const arg = compose(withSaga, withRefreshSaga, withConnect)(SimuPlayerArchive)

export default arg
